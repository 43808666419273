@import "sass/utils/vars";
@import "sass/utils/colors";
.c-order {
  background: $cream;
  @include tablet {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .l-maxwidth {
    max-width: 600px;
  }

  &__close {
    margin-bottom: $spacer * 3;
    @include tablet {
      display: none;
    }
  }
  &__closing-notice {
    color: rgba(0, 0, 0, 0.4);
    margin: $spacer * 3 auto;
  }
  &__inner {
    transition: opacity 0.25s linear;
    &--submitting {
      opacity: 0.5;
    }
    .c-icon-button__icon {
      margin-top: -2px;
      height: 24px;
      width: 24px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .c-icon-button--select-dispensary {
      margin-bottom: $spacer * 2;
      width: 100%;
      max-width: 100%;
    }
    .c-icon-button--set-location,
    .c-icon-button--edit-details,
    .c-icon-button--view-profile {
      // width:calc(100% + #{$spacer*8});
      width: calc(100%);
      border-radius: 0;
      // margin:0 (-$spacer*4);
      max-width: calc(100% + #{$spacer * 8});
      padding-right: $spacer * 5;
      padding-left: $spacer * 5;
      @include tablet {
        margin: 0 0 $spacer;
        max-width: 100%;
        padding-right: $spacer * 3;
        padding-left: $spacer * 3;
        border-radius: $border-radius;
      }
    }
    .c-dispensary-location--appear-done {
      margin: 0 auto;
      width: 100%;
    }
    .c-icon-button--add-details {
      color: $red;
      max-width: 100%;

      .c-icon-button__icon svg {
        width: 13px;
      }
    }
    .c-icon-button--set-location {
      padding-left: $spacer * 2;
      border-bottom: 1px solid $gray30;
      svg {
        margin-top: -$spacer * 0.5;
      }
    }
  }
  h1 {
    margin-bottom: $spacer * 5;
  }
  h3 {
    text-align: center;
    @include tablet {
      text-align: left;
    }
  }
  .c-dispensary-location__details__left h3 {
    text-align: left;
  }
  &__cart-price-error {
    margin: (-$spacer * 2) 0 $spacer * 3;
    color: $red;
  }
  &__hr {
    height: 1px;
    background-color: $gray30;
    margin: $spacer * 2 0;
    border: 0;
    outline: none;
    background: transparent;
    // display:none;
    display: block;
    @include tablet {
      background-color: $gray30;
      margin: $spacer * 5 0;
    }
  }
  .c-button--place-order {
    margin-top: $spacer * 2;
  }
}
