@import 'sass/utils/vars';
@import 'sass/utils/colors';
@import 'sass/utils/typemixins';
.c-inventory-pagination{
  .pagination{
    display:flex;
    justify-content:center;
    align-items:center;
    padding:0;
    li{
      width:30px;
      height:40px;
      background:$white;
      color:$gray90;
      margin:0 $spacer/2;
      border-radius:2px;
      transition:all 0.25s linear;
      padding:2px 0 0;
      display:flex;
      justify-content:center;
      align-items:center;
      @include body--small;
      line-height:1;
      display:flex;
      a{
        color:$charcoal;
        transition:color 0.25s linear;
      }
      &.active{
        background:$deepblue;
        a{
          display:flex;
          align-items:center;
          justify-content:center;
          height:100%;
          width:100%;
          color:$white;
        }
      }
      &.disabled{
        display:none;
      }
    }
  }
}