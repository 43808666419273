@import 'sass/utils/vars';
@import 'sass/utils/colors';
.c-dispensary-location {
  display: flex;
  background: $white;
  // width:calc(100% + #{$spacer*8});
  // margin: 0 (-$spacer*4);
  width: 100%;
  margin: 0;
  overflow: hidden;
  padding: 0 $spacer * 4;
  max-height: 0;
  min-height: 0;
  border-bottom-width: 0;
  opacity: 0;
  .c-inventory & {
    opacity: 1 !important;
    max-height: 100% !important;
  }
  @include tablet {
    width: 100%;
    margin: 0 0 0;
    border-radius: $border-radius * 1.25;
    padding: 0 $spacer * 3 0 $spacer * 2;
  }
  &__inner {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    margin: $spacer * 1.5 0;
  }
  &:last-of-type {
    border-bottom: 0;
  }
  &__figure {
    flex: 0 0 auto;
    width: $spacer * 8;
    height: $spacer * 8;
    background: $gray20;
    border-radius: $border-radius/2;
    overflow: hidden;
    margin-right: $spacer * 2;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      transition: opacity 0.25s linear;
    }
  }
  &__details {
    // display: flex;
    // flex-direction: column;
    // justify-content: center;
    width: 100%;
    overflow: hidden;
    &__wrapper {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      overflow: hidden;
    }
    h3 {
      margin: $spacer/4 0 $spacer;
      .c-order & {
        margin-bottom: $spacer/3;
      }
    }
    &__left {
      margin-top: $spacer * 1;
      flex: 0 0 auto;
      width: 260px;
      @include below-tablet {
        width: 100%;
      }
    }
    @include tablet {
      margin-right: $spacer;
    }
    @include desktop {
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      .c-order & {
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
      }
    }
  }
  &__hours {
    margin-top: $spacer * 1;
    @include desktop {
      align-self: flex-end;
      .c-order & {
        margin-top: $spacer/3;
        align-self: flex-start;
      }
    }
  }
  &__status {
    &--open {
      color: $green;
    }
    &--closed {
      color: $red;
    }
  }
  &__distance-address {
    width: 100%;
    max-width: 100%;
    display: flex;
  }
  &__address {
    display: inline-block;
    flex: 1;
    color: $gray80;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  &__distance {
    display: inline-block;
    flex: 0 0 auto;
    max-width: 0;
    opacity: 0;
    overflow: hidden;
    margin-right: 0;
    background: $gray10;
    padding: 0;
    &.appear-active,
    &.appear-done,
    &.enter-active,
    &.enter-done,
    &.exit {
      opacity: 1;
      padding: 0 ($spacer * 0.75);
      margin-right: $spacer/4;
      max-width: 80px;
    }
    &.enter-active {
      transition: all 0.75s ease-in-out, opacity 0.25s linear 0.5s;
    }
    &.appear-active {
      transition: none;
    }
  }

  &__caret {
    flex: 0 0 auto;
  }
  &--view-order {
    padding-left: $spacer * 2.5;
    height: 90px;
    min-height: 90px;
    @include tablet {
      margin: 0;
      width: 100%;
      margin-bottom: $spacer;
      border-radius: $border-radius;
    }
    .location-icon {
      width: $spacer * 4;
      margin-right: $spacer * 2.5;
    }
  }
  &--enter,
  &--active {
    opacity: 0;
  }

  &--appear-active,
  &--appear-done,
  &--enter-active,
  &--enter-done,
  &--exit {
    border-bottom: 1px solid $gray20;
    max-height: $spacer * 16;
    opacity: 1;
    margin-bottom: $spacer;
    @include tablet {
      margin: 0 0 $spacer;
    }
  }

  &--exit,
  &--enter-done {
    opacity: 1;
    min-height: $spacer * 12;
    .c-dispensary-location__figure,
    .c-dispensary-location__details,
    .c-dispensary-location__details {
      opacity: 1;
    }
  }
  &--exit-active {
    max-height: 0;
    min-height: 0;
    border-bottom-width: 0;
    margin-bottom: 0;
    opacity: 0;
    transition: all 0.5s ease-in-out 0s, opacity 0.25s linear 0s;
    .c-dispensary-location__figure,
    .c-dispensary-location__details,
    .c-dispensary-location__caret {
      // opacity:0;
      transition: opacity 0.25s linear 0s;
    }
  }

  &--exit-done,
  &--enter {
    border-bottom-width: 0;
    opacity: 0;
    min-height: 0;
    .c-dispensary-location__figure,
    .c-dispensary-location__details,
    .c-dispensary-location__caret {
      // opacity:0;
    }
  }

  &--enter-active,
  &--appear-active {
    max-height: $spacer * 12;
    min-height: $spacer * 12;
    border-bottom-width: 1px;
    margin-bottom: $spacer;
    opacity: 1;
    transition: all 0.5s ease-out 0s, opacity 0.25s linear 0.5s, min-height 0.25s ease-in-out 0.25s,
      max-height 0.5s ease-in-out;
    .c-dispensary-location__figure,
    .c-dispensary-location__details,
    .c-dispensary-location__caret {
      opacity: 1;
      transition: opacity 0.25s linear 1s;
    }
  }
}
