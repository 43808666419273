@import 'sass/utils/vars';
@import 'sass/utils/colors';
@import 'sass/utils/typemixins';

.c-create-profile{
  background: linear-gradient(180deg, rgba(198, 200, 225, 0.9) 0%, rgba(224, 186, 197, 0.9) 100%), #FFFFFF;
  padding-bottom:$spacer*10;
  &__inner{
    display:flex;
    flex-direction:column;
  }
  &__figure{
    height:286px;
    width:100%;
    margin-bottom:$spacer*7;
    img{
      object-fit:cover;
      height:100%;
      width:100%;
    }
  }
  &__content{
    padding:0 $spacer*3.5;
    margin:0 auto;
    h2{
      margin-top:$spacer;
      margin-bottom:$spacer*5;
    }
    p{
      @include body--small;
      margin-top:$spacer*2;
    }
    .c-button{
      margin-top:$spacer*5;
    }
  }
  @include desktop{
    padding-bottom:$spacer*15;
    &__inner{
      max-width:1260px;
      margin:0 auto;
      flex-direction:row;
    }
    &__figure{
      flex:1;
      height:auto;
      img{
        max-height:510px;
        max-width:calc(100% - #{$spacer*15});
      }
    }
    &__content{
      flex:1;
      padding-top:$spacer*26;
      padding-left:0;
      &__inner{
        max-width:350px;
      }
    }
  }
}