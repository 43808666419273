@import 'sass/utils/colors';

.c-not-found {
  display:flex;
  flex-direction: column;
  align-items:center;
  justify-content:center;
  text-align: center;
}

.c-not-found-text {
  margin: 22px 0;
}

.c-not-found-text--slim {
  font-size: 60px;
  font-weight: 300;
  margin: 26px 0;
}

.c-not-found-recommendation {
  background-color: $charcoal;
  color: $white;
  padding: 16px 32px;
  font-size: 16px;
  font-weight: bold;
  margin: 22px 0;
}