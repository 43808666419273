@import 'sass/utils/grid';
@import 'sass/utils/vars';
@import 'sass/utils/colors';
@import 'sass/utils/typemixins';

$gridGap: 8px;
$gridGapDesktop: 24px;
.agegate{
  background:$white;
  min-height:calc(100vh - #{$spacer*24.25});
  margin-bottom:-$spacer*1.5;
  @include desktop{
    min-height:calc(100vh - #{$spacer*9.125});
    // background:$cream;
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
  }
  @include tablet-all-orientation{
    min-height:calc(100vh - #{$spacer*13.25});
  }
  &__header{
    @include desktop {
      display:flex;
      flex-direction:column;
      align-items:center;
    }
  }

  .i-logo{
    @include below-tablet{
      width: 60px;
      height: 20px;
      margin-bottom:-$spacer/2;
      *{
        fill:$charcoal;
      }
    }
  }

  &__instruction{
    margin-bottom:$spacer*2;
    @include desktop{
      text-align:center;
    }
  }
  
  &__error{
    color:$red;
    padding:$spacer 0 $spacer;
    @include desktop{
      padding:$spacer 0 $spacer*3;
      margin-top:0;
    }
  }

  &__form-wrapper{
    margin-top:$spacer*4;
    @include desktop{
      max-width:552px;
      margin-top:$spacer*5;
      margin-bottom:$spacer*5;
    }
  }

  &__form{    
    display:flex;
    flex-direction:column;
    align-items:center;
    input{
      width:100%;
      max-width:100%;
      max-height:100% !important;
      @include desktop{
        font-size:32px;
      }
    }
    .form-control__input{
      padding:0;
    }
    button{
      width:100%;
      margin-top:$spacer*2;
    }
    .form-control--checkbox{
      width:100%;
      max-width:300px;
      margin-top:$spacer*3;
      .form-control__styled-checkbox{
        margin-top:4px;
      }
      a{
        text-decoration:underline;
      }
    }
  }

  &__fieldset{
    display:flex;
    flex-wrap:wrap;
    width:calc(100% + #{$gridGap});
    margin: 0 (-$gridGap/2);
    &__label{
      margin-top:$spacer*3;
      text-align:left;
    }
    @include desktop{
      width:calc(100% + #{$gridGapDesktop * 2});
      margin: 0 (-$gridGapDesktop/2) 0;
    }
    &--outside-agegate{
 
      width:calc(100% + #{$spacer});
      margin: $spacer (-$spacer/2);
  
      .form-control{
        margin-bottom:0;  
        input{
          width:100%;
          max-height:100% !important;
        }
      }
      
      .agegate__label{
        color:$gray80;
      }
    
      .agegate__form-column{
        margin:0 $spacer/2;
        width:calc(25% - #{$spacer});
        &--wide{
          width:calc(50% - #{$spacer});
        }
      }
      .agegate__month-input:after, .agegate__day-input:after, .agegate__year-input:after {
        display:none;
      }
    }
  }

  &__form-column{
    flex: 0 0 auto;
    margin:0 ($gridGap/2);
    width:calc(25% - #{$gridGap});

    .form-control__input {
      padding: 0 $spacer*2;
    }

    &--wide{
      width:calc(50% - #{$gridGap});
    }
    @include desktop{
      margin:0 ($gridGapDesktop/2);
      width:calc(25% - #{$gridGapDesktop});

      .form-control__input {
        padding: 0 $spacer*2.7;
      }

      &--wide{
        width:calc(50% - #{$gridGapDesktop});
      }
    }
  }

  &__label{
    display:block;
    padding:$spacer;
    @include body--xsmall;
    text-align:center;
  }

  &__month-input,
  &__day-input,
  &__year-input{
    input{
      text-align:center;
    }
    .agegate & {

      background:$gray10;
      &:not(.form-control--invalid){
        border-color:$gray10;
      }
      &.form-control--active{
        background:$gray30;
      }
    }
    @include desktop{
      .agegate & {
        height:126px;
        background: rgba(19, 19, 17, 0.05);
        &--active{
          background: rgba(19, 19, 17, 0.1);
        }
        input::placeholder{
          color:$gray30 !important;
        }
      }
    }
    &:after{
      content:'';
      position:absolute;
      left:50%;
      top:calc(50% + 16px);
      display:block;
      height:1px;
      width:20px;
      background:transparent;
      transform:translateX(-50%);
      @include desktop{
        top:calc(50% + 32px);
        width:50px;
      }
    }
    &.form-control--active{
      background: rgba(19, 19, 17, 0.1); 
      &:after{
        background:$deepblue;
      }
    }
  }

  &__month-input{
    &:after{
      width:30px;
      @include desktop{
        width:50px;
      }
    }
  }

  &__year-input{
    &:after{
      width:40px;
      @include desktop{
        width:85px;
      }
    }
  }
  &__cookies{
    margin-top:$spacer*3;
    a{
      text-decoration:underline;
    }
    @include desktop{
      text-align:center;

      max-width:300px;
      margin:$spacer*3 auto 0;
      font-size:14px;
    }
  }
}

.agegate__fieldset__label{
  margin-top:$spacer*3;
  margin-bottom:$spacer !important;
  padding-left:$spacer;
  text-align:left !important;
}
