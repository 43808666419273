@import 'sass/utils/colors';
@import 'sass/utils/vars';
.all-locations{
  background:$cream;
  &__search-wrapper{
    position:relative;
    z-index:2;
  }
  .c-dispensaries{
    position:relative;
    z-index:1;
  }
}