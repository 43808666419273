@import 'sass/utils/vars';
@import 'sass/utils/colors';
@import 'sass/utils/typemixins';
.c-inventory-filters-sort{
  position:fixed;
  top:0;
  bottom:0;
  left:0;
  right:0;
  z-index:3;
  h1{
    margin-bottom:0 !important;
  }
  &__backdrop{
    position:fixed;
    top:0;
    bottom:0;
    right:0;
    left:0;
    background-color:$charcoal;
    opacity:0;
  }
  &__content{
    position:fixed;
    bottom:0;
    left:0;
    right:0;
    transform:translateY(100%);
    border-radius:$border-radius-desktop $border-radius-desktop 0 0;
    overflow:hidden;
    background:#fff;
    padding:$spacer*5 $spacer*3.5;
    @include desktop{
      left:auto;
      // width:52.5vw;
      max-width:52.5vw;
      transform:translateX(100%);
      height:100vh;
      // padding-right:180px;
      padding-left:64px;
      padding-right:64px;
      border-radius:$border-radius-desktop 0 0 0;

    }
    h1{
      margin-bottom:0;
    }
    &__inner{
      flex: 1;      
      display:flex;
      flex-direction:column;
      width:100%;
      @include desktop{
        max-width:441px;
      }
    }
  }
  &__header{
    display:flex;
    justify-content:space-between;
    align-items:center;
    margin-bottom:$spacer*5;
  }
  &__close{
    height:$spacer*4;
    width:$spacer*4;
    border-radius:100%;
    background:$cream;
    overflow:hidden;
    display:flex;
    align-items:center;
    justify-content:center;
    padding-bottom:1px;
    margin-left:$spacer*2;
  }
  &--appear{
    .c-inventory-filters-sort{
      &__content{
        transform:translateY(100%);
        box-shadow:none;
        @include desktop{
          transform:translateX(100%);
        }
      }
      &__backdrop{
        opacity:0;
      }
    }
  }
  &--appear-active,
  &--appear-done,
  &--enter-active,
  &--enter-done,
  &--exit{
    .c-filter-form__submit-wrapper{
      transform:translateY(0);
    }
    .c-inventory-filters-sort{
      &__content{
        transform:translateY(0);
        box-shadow:0px 0px 25px rgba(0,0,0,0.25);
        @include desktop{
          transform:translateX(0);
        }
      }
      &__backdrop{
        opacity:0.45;
      }
    }
  }

  &--exit-active,
  &--exit-done{
    .c-inventory-filters-sort{
      .c-filter-form__submit-wrapper{
        transform:translateY(100%);
      }
      &__content{
        transform:translateY(100%);
        box-shadow:none;
        @include desktop{
          transform:translateX(100%);
          box-shadow:none;
        }
      }
      &__backdrop{
        opacity:0;
      }
    }
  }

  &--exit-active{
    .c-filter-form__submit-wrapper{
      transition:transform 0.25s ease-in;
    }
    .c-inventory-filters-sort{
      &__content{
        transition:all 0.5s ease-in-out 0s;
      }
      &__backdrop{
        transition:opacity 0.5s linear 0.5s;
      }
    }
  }

  &--enter-active,
  &--appear-active{
    .c-filter-form__submit-wrapper{
      transition:transform 0.25s ease-in 0.25s;
    }
    .c-inventory-filters-sort{
      &__content{
        transition:all 0.5s ease-out 0.5s;
      }
      &__backdrop{
        transition:opacity 0.5s linear;
      }
    }
  }
}