.i-feeling{
  &--aroused{
    &--st0{
      fill:none;
      stroke-width:2px;
      stroke-linecap:round;
      stroke-miterlimit:10;
    }
    &--st2{
      stroke-linecap:round;
      stroke-miterlimit:10;
    }
  }
  &--creative{
    &--st0{
      fill:none;
      stroke-width:2px;
      stroke-linecap:round;
      stroke-miterlimit:10;
    }
  }
  &--energetic{
    &--st0{
      stroke-width:5px;
      stroke-linejoin:round;
      stroke-miterlimit:10;
    }
    &--st1{
      fill:none;
      stroke-width:2px;
      stroke-linecap:round;
      stroke-miterlimit:10;
    }
  }
  &--euphoric{
    &--st0{
      stroke-width:2;
      stroke-linecap:round;
      stroke-miterlimit:10;
    }
  }
  &--focused{
    &--st0{
      fill:none;
      stroke-width:2px;
      stroke-linecap:round;
      stroke-miterlimit:10;
    }
  }
  &--giggly{
    &--st0{
      fill:none;
      stroke-width:2px;
      stroke-linecap:round;
      stroke-miterlimit:10;
    }
    &--st1{
      stroke-width:5px;
      stroke-linejoin:round;
      stroke-miterlimit:10;
    }
  }
  &--happy{
    &--st0{
      fill:none;
      stroke-width:2px;
      stroke-linecap:round;
      stroke-miterlimit:10;
    }
  }
  &--hungry{
    &--st0{
      stroke-width:2px;
      stroke-linecap:round;
      stroke-miterlimit:10;
    }
	  &--st1{
      fill:none;
      stroke-width:2px;
      stroke-linecap:round;
      stroke-miterlimit:10;
    }
  }
  &--relaxed{
    &--st1{
      fill:none;
      stroke-width:2px;
      stroke-linecap:round;
      stroke-miterlimit:10;
    }
  }
  &--sleepy{
    &--st0{
      stroke-width:5px;
      stroke-linejoin:round;
      stroke-miterlimit:10;
    }
    &--st1{
      fill:none;
      stroke-width:2px;
      stroke-linecap:round;
      stroke-linejoin:round;
      stroke-miterlimit:10;
    }
  }
  &--talkative{
    &--st0{
      fill:none;
      stroke-width:2px;
      stroke-linecap:round;
      stroke-linejoin:round;
      stroke-miterlimit:10;
    }
	  &--st1{
      stroke-width:5px;
      stroke-linejoin:round;
      stroke-miterlimit:10;
    }
  }
  &--tingly{
    &--st0{
      stroke-width:5px;
      stroke-linejoin:round;
      stroke-miterlimit:10;
    }
	  &--st1{
      fill:none;
      stroke-width:2px;
      stroke-linecap:round;
      stroke-miterlimit:10;
    }
  }
  &--uplifted{
    &--st1{
      fill:none;
      stroke-width:2px;
      stroke-linecap:round;
      stroke-miterlimit:10;
    }
  }
}