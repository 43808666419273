@import 'sass/utils/vars';
@import 'sass/utils/colors';
@import 'sass/utils/typemixins';

.c-footer{
  background:$darkcream;
  z-index:0;
  display:flex;
  justify-content:center;
  align-items:center;
  transition:opacity 0.5s ease-in-out 0s, max-height 0s linear 0s;
  &--hidden{
    opacity:0;
    max-height:0 !important;
    transition:opacity 0.5s ease-in-out 0s, max-height 0s linear 0.5s;
    overflow:hidden;
  }
  &__inner{
    display:flex;
    flex-direction:column;
    justify-content:center;
    margin-top:$spacer*-1;
    padding: $spacer*6 $spacer*4 $spacer*4;
    width:90%;
    max-width:920px;
    @include tablet{
      flex-direction:row;
      align-items:center;
      padding: $spacer*5 0 $spacer*3.5;
    }
  }
  .i-logo{
    width:75px !important;
    height:auto !important;
    vertical-align:bottom;
  }
  &__links{
    display:flex;
    flex-wrap:wrap;
    margin-top:$spacer*2;
    
    &__column{
      width:50%;
      @include tablet{
        width:auto;
        display:flex;
      }

    }
    @include tablet{
      flex-wrap:nowrap;
      justify-content:flex-end;
      width:100%;
      margin-top:0;
    }
    a{
      @include body--small;
      display:block;
      margin-bottom:$spacer*1.5;
      font-weight:500;
      @include tablet{
        width:100px;
        text-align:center;
        margin-bottom:0;
      }
      @include desktop{
        width:auto;
        padding:0 16px;
        &:last-of-type{
          padding-right:0;
        }
        text-align:center;
        margin-bottom:0;
      }
    }
  }
  
}