@import 'sass/utils/colors';
@import 'sass/utils/vars';
.c-inventory{
  background:$cream;
  min-height:calc(100vh - 68px);
  h1{
    margin-bottom:$spacer*4;
  }
  &__pickup-location{
    margin-bottom:$spacer;
    opacity:1;
    transition:opacity 0.25s linear;
    .c-dispensary-location{
      padding:0 24px;
    }
    &--transitioning{
      opacity:0;
    }
  }
}