@import "sass/utils/vars";
@import "sass/utils/colors";
@import "sass/utils/typemixins";
.c-pdp-table {
  margin: 0 0 0;
  &__row {
    display: flex;
    padding: $spacer * 2 $spacer * 1;
    border-bottom: 1px solid $gray20;
    &:last-of-type {
      border-bottom: 0;
    }
    > div {
      width: 50%;
    }
    &__value {
      // @include body--small;
      @include table-value;
      padding-left: $spacer * 1;
      span {
        display: block;
        & + span {
          margin-top: 10px;
        }
      }
    }
  }
}
