@import 'sass/utils/colors';
@import 'sass/utils/vars';
.c-tag{
  display:inline-flex;  
  justify-content:center;
  align-items:center;
  border:0;
  outline:0;
  background-color:$gray10;
  margin:0;
  width:auto;
  text-align:center;
  padding:$spacer $spacer*.75;
  margin:0 $spacer $spacer 0;
  border-radius:$border-radius/4;
}