@import 'sass/utils/vars';
@import 'sass/utils/colors';
@import 'sass/utils/typemixins';
.c-order-totals{
  display:flex;
  flex-direction:column;
  margin-top:$spacer*1.5;
  &__subtotals{
    padding:0 $spacer*3 $spacer*3;
    border-bottom:1px solid $gray30;
    @include tablet{
      padding:$spacer*3;
      border-top:1px solid $gray30;
    }
  }
  &__total{
    padding:$spacer*3;
  }
  &__row{
    display:flex;
    justify-content:space-between;
    .slug{
      color:$gray90;
    }
    &__price{
      @include body--small;
    }
  }
}