@import 'sass/utils/colors';

.c-unsupported {
  height: 100vh;
  width: 100vw;
  display:flex;
  flex-direction: column;
  align-items:center;
  justify-content:center;
  text-align: center;
}

.c-unsupported-text {
  margin: 22px 0;
}