@import "sass/utils/vars";
@import "sass/utils/colors";
@import "sass/utils/typemixins";

.c-slider {
  position: relative;
  border-radius: $border-radius;
  background: $white;
  margin-bottom: $spacer * 2;
  padding: $spacer * 3 $spacer * 5 $spacer * 4;
  p {
    margin-left: -$spacer * 2;
  }
  &__wrapper {
    position: relative;
    margin-top: $spacer;

    &:before {
      content: "";
      position: absolute;
      left: -$spacer * 2;
      right: -$spacer * 2;
      top: 50%;
      border: 1px solid $gray30;
    }
  }

  &__handle {
    position: relative;
    width: 1px;
    display: flex;
    justify-content: center;
    z-index: 5;
    padding: 0;
    span {
    }
    &__inner {
      position: relative;
      z-index: 5;
      flex: 0 0 auto;
      display: block;
      width: $spacer * 5;
      height: $spacer * 3;
      border-radius: 30px;
      transform-origin: left;
      overflow: hidden;
      z-index: 5;
      span {
        transition: background-color 0.25s linear;
        display: block;
        width: 100%;
        height: 100%;
        position: relative;
      }
      &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 0;
        background: $white;
      }
      // position:absolute;
      // top:0;
      // left:0;
      // right:0;
      // bottom:0;
      // z-index:1;
    }
  }
  //feelings static colors
  &__feeling {
    background-color: rgba(243, 41, 174, 0.25);
    &-energetic {
      background-color: $energetic-bg;
    }
    &-focused {
      background-color: $focused-bg;
    }
    &-uplifted {
      background-color: $uplifted-bg;
    }
    &-euphoric {
      background-color: $euphoric-bg;
    }
    &-talkative {
      background-color: $talkative-bg;
    }
    &-creative {
      background-color: $creative-bg;
    }
    &-happy {
      background-color: $happy-bg;
    }
    &-hungry {
      background-color: $hungry-bg;
    }
    &-giggly {
      background-color: $giggly-bg;
    }
    &-relaxed {
      background-color: $relaxed-bg;
    }
    &-sleepy {
      background-color: $sleepy-bg;
    }
    &-aroused {
      background-color: $aroused-bg;
    }
    &-tingly {
      background-color: $tingly-bg;
    }
  }

  &__labels {
    position: absolute;
    bottom: $spacer;
    left: 0;
    right: 0;
    display: flex;
    justify-content: space-between;
    @include body;
    color: $gray30;
    left: $spacer * 3;
    right: $spacer * 3;
  }
  &__steps {
    position: absolute;
    height: $spacer;
    display: flex;
    justify-content: space-between;
    z-index: 0;
    top: calc(50% + 2px);
    left: 0;
    right: 0;
    transform: translateY(-50%);

    &__step {
      width: 1px;
      height: $spacer * 0.75;
      background: $gray20;
    }
  }
}
