@import 'sass/utils/vars';
@import 'sass/utils/colors';
@import 'sass/utils/typemixins';
.c-forgot-password-form{
  margin:$spacer*5 auto 0;


  
  .form-control{
    margin-bottom:$spacer;
    input{
      width:calc(100% - #{$spacer*2});
    }
  }
  .c-button{
    margin-top:$spacer*5;
    &--success{
      background:$green;
      opacity:0.75;
      cursor:default;
    }
  }
}