@import 'sass/utils/colors';
@import 'sass/utils/vars';
.c-icon-button{
  display:flex;
  justify-content:space-between;
  align-items:center;
  border:0;
  outline:0;
  background:$charcoal;
  color:#fff;
  width:100%;
  height:52px;
  opacity:1;
  padding-top:1px;
  padding:1px $spacer*2 0 $spacer*2.5;
  transition:opacity 0.25s linear;
  border-radius:$border-radius;
  cursor:pointer;
  &--outlined{
    background:transparent;
    border:1px solid $charcoal;
    color:$charcoal;
  }
  @include desktop{
    max-width:314px;
  }
  &:disabled{
    opacity:0.25;
  }
  &__icon{
    margin-right:$spacer*3;
    height:$spacer*2;
    svg{
      object-fit:contain;
      width:100%;
      height:100%;
    }
  }
  img{
    object-fit: contain;
    height: 100%;
    width: 100%;
  }
  span{
    flex:1;
  }
  .c-card-gradient{
    position:relative;
    width:100%;
    height:100%;
    object-fit:cover;
    border-radius:100%;
    overflow:hidden;
  }
}