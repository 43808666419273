@import "sass/utils/vars";
@import "sass/utils/colors";
@import "sass/utils/typemixins";
.c-signin {
  background: $cream;
  padding-top: $spacer * 2;
  opacity: 1;
  transition: opacity 0.25s linear;
  &--signing-in {
    opacity: 0.5;
  }
  .c-signin__title {
    font-size: 28px;
  }
  h1 {
    padding-right: $spacer * 2;
    text-align: center;
    @include tablet {
      text-align: center;
      padding-right: 0;
    }
  }
  p {
    margin: $spacer * 3 auto 0;
    max-width: 400px;
    padding-right: $spacer * 2;
    @include tablet {
      text-align: center;
      padding-right: 0;
    }
  }

  &__required-confirmation {
    color: $red;
    text-align: center;
    @include body;
    max-width: 325px;
    margin: $spacer * 3 auto (-$spacer);
  }
  &__signup {
    margin: $spacer * 5 0;
    text-align: center;
    @include body--small;
    a {
      font-weight: 700;
    }
  }
  &__options {
    max-width: 351px;
    margin: 0 auto;
    .c-button {
      margin: 0 auto;
      max-width: 314px;
    }
  }
  &__failure {
    text-align: center;
    color: $red;
    margin: $spacer * 2 0 (-$spacer * 3);
  }
  &__verification-message {
    text-align: left;

    @include tablet {
      margin-top: $spacer * 10;
      text-align: center;
    }
    h1 {
      margin-bottom: $spacer * 5;
    }
    p {
      margin: 0 auto $spacer * 1.5;
      strong {
        color: $deepblue;
      }
      @include tablet {
        max-width: 100%;
      }
    }
    .c-button {
      margin: $spacer * 5 auto 0;
    }
  }
  &__close {
    margin-bottom: $spacer * 5.5;
    padding-left: 0;
    @include tablet-only {
      display: block;
      width: 400px;
      text-align: left;
      margin-left: auto;
      margin-right: auto;
    }
    @include desktop {
      display: none;
    }
  }
  &__failure {
    display: block;
    @include body--small;
    margin-bottom: -#{$spacer * 3} !important;
  }
  .c-icon-button {
    text-align: left;
  }
}
