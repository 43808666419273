@import "sass/utils/vars";
@import "sass/utils/colors";
@import "sass/utils/typemixins";
.c-product-reviews {
  background: $cream;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  height: 820px;
  max-height: 0;
  opacity: 0;
  @include desktop {
    height: 980px;
  }

  .c-card-gradient canvas {
    height: 300%;
  }

  &--appear,
  &--enter {
    max-height: 0;
    opacity: 0;
  }

  &--appear-active,
  &--appear-done,
  &--enter-active,
  &--enter-done,
  &--exit {
    max-height: 780px;
    opacity: 1;
    @include desktop {
      max-height: 980px;
    }
  }

  &--exit-done,
  &--exit-active {
    max-height: 0;
    opacity: 0;
  }

  &--enter-active,
  &--appear-active {
    transition: max-height 0.5s ease-in-out 0.5s, opacity 0.25s linear 0.75s;
  }

  &--exit-active {
    transition: max-height 0.5s ease-in-out, opacity 0.25s linear;
  }

  &__inner {
    flex: 1;
    position: relative;
    width: 100%;
    max-width: 920px;
    margin: 0 auto;
    padding: $spacer * 5 $spacer * 3 $spacer * 10;
    display: flex;
    flex-direction: column;
    height: 100%;
    .slug {
      margin-bottom: $spacer * 3;
    }
    @include desktop {
      box-sizing: content-box;
      padding: $spacer * 20 0;
    }
    .c-product-reviews__slide {
      height: 600px;
      max-width: 100%;
      @include desktop {
        max-width: 356px;
      }
      &--reviewed {
        max-width: 0;
        margin-right: 0 !important;
        transition: all 0.5s ease-in-out 0.5s;
      }
    }
    .swiper-container,
    .swiper-wrapper {
      overflow: visible;
      max-width: 100%;
      flex: 1;
      margin: 0;
    }
  }
  &__nav {
    display: flex;
    justify-content: space-between;
    @include desktop {
      justify-content: flex-start;
    }
    &__next,
    &__prev {
      padding: 0;
    }
    &__prev {
      margin-right: $spacer;
    }
    &__pages {
      display: flex;
      justify-content: flex-start;
      &__button {
        color: rgba(0, 0, 0, 0.4);
        transition: color 0.25s linear;
        font-size: 12px;
        padding: 0;
        margin-right: $spacer;
        &--active {
          color: $charcoal;
        }
      }
    }
  }
  &__all-done {
    background: rgba(255, 255, 255, 0.9);
    border-radius: $border-radius;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    padding-bottom: $spacer * 10;
    height: 100%;
    box-sizing: border-box;
    .c-button {
      position: absolute;
      bottom: $spacer * 3;
      max-width: 266px;
      left: 50%;
      transform: translateX(-50%);
    }
    @include below-tablet {
      height: 100%;
      padding-top: 0;
    }
    // @include desktop{
    //   position:absolute;
    //   top:50%;
    //   left:50%;
    //   transform:translate(-50%, -50%);
    //   padding:$spacer*12 0 0;
    //   background:transparent;

    // }
    h1 {
      text-align: center;
      font-size: 32px;
    }
    &--appear,
    &--enter,
    &--exit-done {
      opacity: 0;
    }

    &--appear-active,
    &--appear-done,
    &--enter-active,
    &--enter-done {
      opacity: 1;
    }
    &--appear-active,
    &--enter-active {
      transition: opacity 0.5s linear 0s;
    }
  }
}

.c-review-card {
  border-radius: $border-radius;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  transition: opacity 0.25s linear;
  &--reviewing {
    opacity: 0.5;
  }
  &--reviewed {
    opacity: 0;
  }
  &__topper {
    position: relative;
    width: 100%;
    min-height: 174px;
    z-index: 0;
    figure {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    &__details {
      position: relative;
      z-index: 1;
      &__row {
        display: flex;
        height: $spacer * 7;
        border-bottom: 1px solid rgba(255, 255, 255, 0.2);
        &__icon {
          border-right: 1px solid rgba(255, 255, 255, 0.2);
          width: $spacer * 8.5;
          display: flex;
          justify-content: center;
          align-items: center;
          div {
            max-width: calc(100% - #{$spacer * 4});
            max-height: calc(100% - #{$spacer * 2});
            height: 100%;
            width: 100%;
            object-fit: contain;
            svg {
              width: 100%;
              height: 100% !important;
              object-fit: contain;
              * {
                stroke: #fff;
              }
            }
          }
        }
        &__title {
          flex: 1;
          display: flex;
          align-items: center;
          padding-left: $spacer * 1.25;
          @include meta;
          color: $white;
        }
      }
      &__name-vendor {
        padding: $spacer * 2 $spacer * 2 $spacer * 2 $spacer * 3;
        h3 {
          color: $white;
        }
        &__vendor {
          opacity: 0.4;
          line-height: 1.4 !important;
        }
      }
    }
  }
  &__sliders {
    background: $white;
    border-radius: $border-radius;
    margin-top: -$border-radius;
    z-index: 1;
    padding: $spacer * 3.25 0 $spacer * 2.5;
    .slug {
      padding: 0 $spacer * 3;
    }
    .c-slider {
      margin: 0;
      padding-top: 0;
    }
    .c-button {
      margin: $spacer * 2 auto 0;
      max-width: 266px;
    }
  }
}
