@import 'sass/utils/vars';
@import 'sass/utils/colors';
@import 'sass/utils/typemixins';
.c-order-history{
  h1{
    @include tablet{
      text-align:center;
    }
  }
  &__orders{
    margin-top:$spacer*5;
    .c-order-history-order{
      margin-bottom:$spacer*5;
    }
  }
}