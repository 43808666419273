@import "sass/utils/vars";
@import "sass/utils/colors";
@import "sass/utils/typemixins";
.c-forage-embed {
  display: flex;
  flex-wrap: wrap;
  // position: fixed;
  justify-content: center;
  // background: rgba($color: #fff, $alpha: 0.84);
  z-index: 14;
  inset: 0;
  position: absolute;
  align-items: center;
  &__title {
    margin-bottom: 20px;
    text-transform: none;
    color: #fff;
    width: 100%;
  }
  &__cta-Wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    text-align: center;
    margin: 0 auto;
    width: 500px;
    max-width: 100%;
    padding: 40px 10px;
    .c-button {
      background: #fff !important;
      color: #131311;
      margin-bottom: 10px;
      width: 49%;
      @media (max-width: 575px) {
        max-width: 258px;
        width: 100%;
        margin-left: auto;
        margin-right: auto;
      }
    }
  }
}
