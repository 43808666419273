@import "sass/utils/colors";
@import "sass/utils/vars";
.c-inventory-search {
  position: relative;
  // width:calc(100% + #{$spacer * 4});
  // margin: 0 (-$spacer*2);
  width: 100%;
  margin: 0;
  &__input {
    background: rgba(19, 19, 17, 0.07);
    height: $spacer * 8;
    border: 0px;
  }
  &__reset {
    position: absolute;
    width: $spacer * 5;
    height: $spacer * 5;
    right: $spacer * 2;
    top: 50%;
    transform: translateY(-50%);
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.25s linear;
    z-index: 2;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    &--visible {
      opacity: 1;
      pointer-events: auto;
    }
  }

  .form-control__icon {
    top: calc(50% + 3px);
  }
  @include tablet {
    width: 100%;
    margin: 0;
  }
}
