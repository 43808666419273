@import 'sass/utils/vars';
@import 'sass/utils/colors';
@import 'sass/utils/typemixins';
.c-pdp-budtender{
  margin:$spacer*10 0 ;
  width:100%;
  &__review{
    width:100%;
    margin-top:$spacer*2;
    padding:$spacer*3;
    background:$deepblue;
    border-radius:20px 20px 0 20px;
    color:$white;
  }
  &__name{
    display:block;
    @include body--small;
    margin-bottom:$spacer;
  }
  &__content{
    @include body;
  }
}