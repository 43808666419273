@import "sass/utils/colors";
@import "sass/utils/vars";
@import "sass/utils/typemixins";

.form-control {
  position: relative;
  &__error {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: $spacer/2 0 $spacer * 2 ($spacer);
    span {
      color: $red;
    }
    &__alert-icon {
      margin-right: $spacer;
      height: $spacer * 2;
      width: $spacer * 2;
      background: $red;
      border-radius: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      color: $white;
      span {
        color: $white;
        font-size: 10px;
        font-weight: bold;
      }
    }
  }
  &--text {
    background: rgba(19, 19, 17, 0.05);
    border-radius: $border-radius;
    height: 74px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border: 1px solid $gray10;
    &:not(.form-control--placeholder) {
      padding-top: 0;
      transition: all 0.25s linear 0.25s;
    }
    @include desktop {
      background: rgba(19, 19, 17, 0.05);
    }
  }
  &--checkbox {
    margin-bottom: $spacer * 2;
    &.form-control--invalid {
      .form-control__styled-checkbox {
        border-color: $red;
      }
    }
  }
  &__multi-values {
    display: flex;
    position: absolute;
    left: $spacer * 3;
    right: $spacer * 3;
    bottom: $spacer * 1.5;
    &__value {
      padding: $spacer/4 $spacer/2;
      background: rgba(19, 19, 17, 0.07);
      color: $charcoal;
      margin-right: $spacer/2;
      border-radius: $spacer/2;
    }
  }

  &__content {
    width: 100%;
    height: 100%;
    display: flex;
  }
  &--has-icon {
    .form-control__input {
      padding: 0 0 0 $spacer * 6;
      width: 100%;
    }
    .form-control__label {
      left: $spacer * 6;
    }
  }
  &__icon {
    position: absolute;
    top: calc(50% + 1px);
    left: $spacer * 1.75;
    transform: translateY(-50%);
  }
  &__label {
    @include h3;
    line-height: 100%;
    position: absolute;
    top: 50%;
    left: $spacer * 3;
    transform: translateY(-50%);
    transform-origin: left top;
    transition: all 0.25s linear 0.25s;
    pointer-events: none;
    color: $gray80;
  }
  &__checkbox-label {
    display: flex;
    overflow: hidden;

    input {
      position: absolute;
      left: -5555px;
    }
  }

  &__styled-checkbox {
    position: relative;
    margin-right: $spacer;
    width: $spacer * 2;
    height: $spacer * 2;
    border: 1px solid $charcoal;
    border-radius: $border-radius/2;
    margin-top: 2px;
    flex: 0 0 auto;

    &--checked {
      &:after {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: $spacer;
        height: $spacer;
        background: $charcoal;
        border-radius: $border-radius/4;
      }
    }
  }

  &__input {
    height: 100%;
    display: block;
    outline: 0;
    border: 0;
    background: transparent;
    background-image: none !important;
    line-height: 1.5;
    overflow: visible;
    @include h3;
    padding: 0 $spacer * 3;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-family: "Sneak";
    &:focus {
      text-overflow: clip;
    }
    &[type="number"] {
      -moz-appearance: textfield;
      -moz-appearance: textfield;
    }
    @include desktop {
      width: 100%;
    }
  }
  &--active {
    background: rgba(19, 19, 17, 0.1);
  }
  &--invalid {
    border-color: $red;
    .form-control__label {
      color: $red;
    }
  }
  &--disabled {
    background: rgba(19, 19, 17, 0.2);
  }
  .form-control__input:-webkit-autofill ~ .form-control__label {
    transition: all 0.25s linear;
    color: $deepblue;
    transform: scale(0.66) translateY(-50%);
    top: $spacer * 2.5;
  }
  &--active,
  &--dirty {
    &.form-control--text {
      &:not(.form-control--placeholder) {
      }
      .form-control__label {
        top: $spacer * 2.5;
        transition: all 0.25s linear;
        color: $deepblue;
        transform: scale(0.66) translateY(-50%);
      }
      .form-control__input {
        align-self: flex-end;
        max-height: calc(100% - #{$spacer * 2});
      }
    }
  }

  &--placeholder {
    input::placeholder {
      color: $gray30 !important;
    }
  }
  &--select {
    z-index: 6;
    &--ui {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      margin-bottom: 0 !important;
      &__trigger {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
      }
      &__options {
        position: absolute;
        top: calc(100% - 8px);
        left: 0;
        right: 0;
        max-height: 0;
        overflow: hidden;
        background: #eae7e3;
        transition: max-height 0.25s ease-in-out;
        z-index: 5;
        border-radius: 0 0 $spacer $spacer;
        padding-top: $spacer;
        border: 1px solid transparent;
        &:before {
          content: "";
          display: none;
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          height: $spacer;
          border-radius: 0 0 $spacer $spacer;
          background: #eae7e3;
        }
      }
      &__option {
        height: 60px;
        width: 100%;
        padding: $spacer * 2 $spacer * 3;
        opacity: 0;
        background-color: $cream;
        transition: opacity 0.25s linear 0s, background-color 0.25s linear;
        cursor: pointer;
        &:hover,
        &.form-control--select--ui__option--is-hover {
          background-color: $darkcream;
        }
      }
      &--is-active {
        .form-control--select--ui {
          &__options {
            border-color: $darkcream;
          }
          &__option {
            opacity: 1;
            transition: opacity 0.25s linear 0.25s,
              background-color 0.25s linear;
          }
        }
      }
    }
    .down-caret-icon {
      position: absolute;
      top: 50%;
      right: $spacer * 3;
    }
    select {
      width: 100%;
      -webkit-appearance: none;
      appearance: none;
    }
    .form-control {
      &__select-options {
        max-height: 0;
        overflow: hidden;
        position: absolute;
        top: calc(100% - #{$spacer});
        left: 0;
        right: 0;
        border-radius: 0 0 $spacer $spacer;
        background: $darkcream;
        padding-top: 0;
        transition: all 0.25s linear;
        z-index: 5;
        &__option {
        }
        &--open {
          padding-top: $spacer;
          .form-control__select-options__option {
            opacity: 1;
            transition: opacity 0.25s linear 0.25s;
          }
        }
      }
    }
  }
  input::placeholder {
    color: transparent;
  }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  @-webkit-keyframes autofill {
    0%,
    100% {
      color: #666;
      background: transparent;
    }
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
  }
}

.i-checkbox-group {
  &--two-wide {
    display: flex;
    flex-wrap: wrap;
    .form-control--checkbox {
      width: 50%;
    }
  }
}

.i-radio-group {
  .form-control {
    &--radio {
      margin-bottom: $spacer * 2;
      &:last-of-type {
        margin-bottom: 0;
      }
    }
    &__radio-label {
      display: flex;
      align-items: center;
      overflow: hidden;
      cursor: pointer;
      input {
        position: absolute;
        left: -5555px;
      }
    }
    &__styled-radio {
      position: relative;
      margin-right: $spacer;
      width: $spacer * 2;
      height: $spacer * 2;
      border: 1px solid $charcoal;
      border-radius: 100%;
      margin-bottom: 0;
      &--checked {
        &:after {
          content: "";
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: $spacer;
          height: $spacer;
          background: $charcoal;
          border-radius: 100%;
        }
      }
    }
  }
  &--two-wide {
    display: flex;
    flex-wrap: wrap;
    .form-control--checkbox {
      width: 50%;
    }
  }
}

.i-checkout-cards {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  .form-control {
    &--checkbox-card {
      border: 1px solid $gray20;
      width: calc(33.33% - #{$spacer * 0.75});
      margin-bottom: $spacer * 0.75;
      border-radius: $border-radius;
      transition: background-color 0.25s linear;
      @include tablet-all-orientation {
        padding: 0 0 $spacer * 2;
      }
      .form-control__checkbox-label {
        cursor: pointer;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 124px;
        padding: $spacer * 1.25 0;
        @include desktop {
          height: 172px;
        }
        &__icon-wrapper {
          flex: 1;
          display: flex;
          align-items: center;
          justify-content: center;
          .i-feeling {
            width: 120px;
            max-width: 90%;
          }
        }
        span {
          @include body--xsmall;
          color: $charcoal;
          transition: color 0.25s linear;
        }
      }
      .checkmark-icon {
        position: absolute;
        top: $spacer * 1.5;
        right: $spacer * 1.25;
        opacity: 0;
        transition: opacity 0.25s linear;
      }
    }
    &.form-control--checked {
      .form-control__checkbox-label {
        .checkmark-icon {
          opacity: 1;
        }
        span {
          color: $white;
        }
      }
    }
  }
}

.selectNative,
.selectCustom {
  position: relative;
  width: 22rem;
  height: 4rem;
}

// Make sure the custom select does not mess with the layout
.selectCustom {
  position: absolute;
  top: 0;
  left: 0;
  display: none;
}

// This media query detects devices where the primary
// input mechanism can hover over elements. (e.g. computers with a mouse)
@media (hover: hover) {
  // Since we are using a mouse, it's safe to show the custom select.
  .selectCustom {
    display: block;
  }

  // In a computer using keyboard? Then let's hide back the custom select
  // while the native one is focused:
  .form-control--select:focus + .form-control--select--ui {
    display: none;
  }
}

/* Add the focus states too, They matter, always! */
.selectNative:focus,
.selectCustom.isActive .selectCustom-trigger {
  outline: none;
  box-shadow: white 0 0 0 0.2rem, #ff821f 0 0 0 0.4rem;
}

//
// Rest of the styles to create the custom select.
// Just make sure the native and the custom have a similar "box" (the trigger).
//

.select {
  position: relative;
}

.selectLabel {
  display: block;
  font-weight: bold;
  margin-bottom: 0.4rem;
}

.selectWrapper {
  position: relative;
}

.selectNative,
.selectCustom-trigger {
  font-size: 1.6rem;
  background-color: #fff;
  border: 1px solid #6f6f6f;
  border-radius: 0.4rem;
}

.selectNative {
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
  background-repeat: no-repeat;
  background-position-x: 100%;
  background-position-y: 0.8rem;
  padding: 0rem 0.8rem;
}

.selectCustom-trigger {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: #fff;
  padding: 0.8rem 0.8rem;
  cursor: pointer;
}

.selectCustom-trigger::after {
  content: "▾";
  position: absolute;
  top: 0;
  line-height: 3.8rem;
  right: 0.8rem;
}

.selectCustom-trigger:hover {
  border-color: #8c00ff;
}

.selectCustom-options {
  position: absolute;
  top: calc(3.8rem + 0.8rem);
  left: 0;
  width: 100%;
  border: 1px solid #6f6f6f;
  border-radius: 0.4rem;
  background-color: #fff;
  box-shadow: 0 0 4px #e9e1f8;
  z-index: 1;
  padding: 0.8rem 0;
  display: none;
}

.selectCustom.isActive .selectCustom-options {
  display: block;
}

.selectCustom-option {
  position: relative;
  padding: 0.8rem;
  padding-left: 2.5rem;
}

.selectCustom-option.isHover {
  background-color: #865bd7; // contrast AA
  color: white;
}

.selectCustom-option:not(:last-of-type)::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  border-bottom: 1px solid #d3d3d3;
}

.selectCustom-option.isActive::before {
  content: "✓";
  position: absolute;
  left: 0.8rem;
}

// ----- Theme styles -----

html {
  font-size: 62.5%;
}
body {
  background: #f8f3ef;
  font-family: Arial, Helvetica, sans-serif;
  box-sizing: border-box;
  color: #343434;
  line-height: 1.5;
  font-size: 1.6rem;
}

body * {
  box-sizing: inherit;
}

strong {
  font-weight: 600;
}

.title {
  font-size: 2rem;
  font-weight: 600;
  margin: 1.6rem;
  line-height: 1.2;
  text-align: center;
}

.card {
  position: relative;
  margin: 2rem auto;
  max-width: calc(100% - 2rem);
  width: 40rem;
  background: white;
  padding: 3rem;
  box-shadow: 0.2rem 0.2rem #e9e1f8;
}

.inst {
  margin-bottom: 1rem;
}

.note {
  font-size: 1.4rem;
  margin: 2rem 0 0;
  color: #6b6b6b;
}

.link {
  display: inline-block;
  color: inherit;
  text-decoration-color: #9b78de;
  padding: 0.1rem 0.2rem;
  transform: translateX(-0.1em);
  margin-right: -0.1em;

  &:hover {
    color: #8c00ff;
  }

  &:focus {
    outline: none;
    background-color: #e9e1f8;
  }
}
