@import "sass/utils/vars";
@import "sass/utils/colors";
@import "sass/utils/typemixins";
.c-order-history-order {
  border-radius: $border-radius-desktop;
  overflow: hidden;
  border: 1px solid $gray30;
  .slug {
    color: $deepblue;

    &--red {
      color: $red;
    }
  }
  &__item__description {
    display: flex;
    width: 100%;
  }
  &__status {
    padding: $spacer * 3;
    @include tablet {
      padding: $spacer * 3 $spacer * 4.5;
    }

    &--reason {
      margin-top: $spacer;
      font-weight: 500;
      font-size: 12px;
      line-height: 18px;
      letter-spacing: 0.06em;
    }
  }
  &__dispensary {
    display: flex;
    flex-direction: column;
    margin-top: $spacer * 1.5;
    @include body;
  }
  &__details {
    border-radius: $border-radius-desktop;
    background: $white;
    padding: $spacer * 3 0 0;
    > .slug {
      margin-left: $spacer * 3;
      @include tablet {
        margin-left: $spacer * 4.5;
      }
    }
  }
  &__item__description__name {
    font-weight: 500;
    color: $gray90;
    min-width: 120px;
    margin-right: 10px;
    font-size: 12px;
  }
  &__item__description__value {
    font-weight: 500;
    color: $charcoal;
    font-size: 14px;
  }
  &__items {
    padding: $spacer $spacer * 3;
    @include tablet {
      padding: $spacer $spacer * 4.5;
    }
  }
  .c-order-totals__subtotals,
  .c-order-totals__total {
    @include tablet {
      padding-left: $spacer * 4.5;
      padding-right: $spacer * 4.5;
    }
  }
  &__card {
    border-bottom: 1px solid $gray20;
    @include tablet {
      &:last-of-type {
        border-bottom: 0;
      }
    }
    padding-bottom: $spacer * 2.5;
    & + & {
      margin-top: $spacer * 2.5;
    }
  }
  &__dics {
    display: flex;
    font-size: 14px;
    line-height: 18px;
    padding-top: $spacer * 1;
    color: #76766b;
  }
  &__item {
    display: flex;
    font-size: 14px;
    line-height: 18px;
    padding: $spacer * 1.5 0;
    @include tablet {
      &:last-of-type {
        border-bottom: 0;
      }
    }
    &__details {
      flex: 1;
      display: flex;
      flex-direction: column;
    }
    &__name,
    &__vendor {
      font-size: 14px;
      line-height: 18px;
      &--strikethrough {
        text-decoration: line-through;
        display: inline-block;
        margin-right: $spacer;
      }
    }
    &__vendor {
      opacity: 0.5;
    }
    &__meta {
      margin-top: $spacer;
      @include body--xsmall;
    }
    &__price {
      flex: 0 0 auto;
    }
  }
  &__item__pickup {
    display: flex;
    flex-direction: column;
    border-bottom: 0;
    margin-top: $spacer * 2;

    &:first-of-type {
      margin-top: 0;
    }
  }
}
