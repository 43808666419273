@import 'sass/utils/vars';
@import 'sass/utils/colors';
@import 'sass/utils/typemixins';

.c-order-confirmation{  
  padding:0 !important;
  &__close{
    display:block;
    margin-bottom:$spacer*3;
    color:$gray90;
    svg{
      margin-right:$spacer/2;
    }
  }
  &__top{
    position:relative;  
    padding:$spacer*8.5 $spacer*4 $spacer*5;
    background:$white;
    border-radius:0 0 $border-radius-desktop $border-radius-desktop;
    box-shadow: 0px 84px 84px rgba(117, 87, 53, 0.1);
    @include tablet{
      padding:$spacer*11.5 $spacer*4 $spacer*9;

    }
    h1{
      margin-bottom:$spacer*5;      
      // @include tablet{
      //   display:flex;
      //   align-items:center;
      //   margin-bottom:$spacer*8.5;
      // }
      svg{
        margin-left:$spacer;
        @include tablet{
          display:inline-block;
        }
      }
    }
  }
  &__bottom{
    margin-top:-$spacer*2.5;
    padding:$spacer*7.5 $spacer*4;
    background:$cream;
    border-radius:0 0 $border-radius-desktop $border-radius-desktop;
  
    .c-button--view-account{
      margin:$spacer*5 0 $spacer;
    }
    @include tablet{
      padding:$spacer*12.5 0;
      h3{
        padding-right:30px;
      }
    }

  }
  &__pickup-details{
    border-radius:$border-radius;
    border:1px solid $gray30;
    margin-top:$spacer*3;
    background:$white;
    @include tablet{
      margin-top:$spacer*5;
    }
    .slug{
      margin-bottom:$spacer*2;
      color:$deepblue;
    }
    &__value{
      @include body;
      &--directions{
        font-size:16px;
      }
    }
    &__note{
      margin-top:$spacer*2;
      @include body--small;
      color:$gray80;
    }
    &__row{
      padding:$spacer*3 $spacer*3;
      border-bottom:1px solid $gray30;
      &:last-of-type{
        border-bottom:0;
      }
      @include tablet{
        padding:$spacer*3 $spacer*4;
      }
    }
  }
}