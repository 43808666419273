@import "sass/utils/vars";
@import "sass/utils/colors";
@import "sass/utils/typemixins";
$cardTransitionTime: 0.25s;
$activeTransitionDelay: 0s;

@mixin activeCardHover {
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.375);
}
@mixin activeCard {
  &.c-product-card--no-feelings {
    transition: all 0.5s linear;
    .c-product-card__action svg path,
    .c-product-card__action svg circle {
      transition: all 0.25s linear;
    }
  }
  &:not(.c-product-card--no-feelings) {
    .c-card-gradient {
      opacity: 1;
      transition: opacity 1.25s ease-in-out 0s;
      &__placeholder {
        opacity: 0;
        transition: opacity 0.5s ease-in-out 0s;
      }
    }
    .c-product-card {
      &__content {
        &__details {
          &__name,
          &__brand {
            color: $white;
            transition-delay: 0.25s;
          }
        }
        &__top-section {
          &__price {
            color: $white;
            transition-delay: 0.25s;
          }
          &__classification {
            .i-product-type {
              path,
              circle,
              line,
              polyline,
              polygon,
              rect {
                stroke: #fff !important;
                transition: stroke 0.5s linear 0.25s;
              }
            }
          }
        }
        &__discounts {
          color: $white;
          transition-delay: 0.25s;
          .slug {
            color: $white;
            transition-delay: 0.25s;
          }
        }
      }
      &__actions {
        &__incart,
        &__price {
          color: $white;
          transition-delay: 0.25s;
        }
      }
      &__action {
        svg {
          circle {
            fill: transparent;
            stroke: $white !important;
            transition: all 0.5s linear 0.25s;
          }
          path {
            fill: $white;
            transition: all 0.5s linear 0.25s;
          }
        }
      }
    }
  }
}

.c-product-card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 224px;
  box-shadow: none;
  background-color: $white;
  border: none;
  border-radius: $border-radius * 1.25;
  transition: all $cardTransitionTime linear, box-shadow 0.5s linear 0.5s;
  overflow: hidden;
  z-index: 1;

  &__default-gradient {
    position: absolute;
    top: $spacer * 1.5;
    right: $spacer * 1.5;
    width: $spacer * 4;
    height: $spacer * 4;
    border-radius: 100%;
    border: 1px solid $darkcream;
    overflow: hidden;

    &:before {
      content: "";
      display: block;
      height: $spacer * 5;
      width: 1px;
      background: $darkcream;
      transform: rotate(-45deg);
      transform-origin: left top;
    }
  }

  .c-card-gradient {
    opacity: 0;
    transition: opacity 0.75s ease-in-out 0s;
    &__placeholder {
      opacity: 1;
      transition: opacity 0.5s ease-in-out 0.25s;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    flex: 1;
    width: 100%;
    text-align: left;
    font-size: 20px;
    color: $charcoal;
    z-index: 3;
    height: 100%;
    transition: color $cardTransitionTime linear 0.5s;

    &__top-section {
      flex: 0 0 auto;
      display: flex;
      align-items: center;
      height: 60px;
      padding-right: $spacer * 1.5;
      border-bottom: 1px solid $gray30;
      transition: border-color $cardTransitionTime linear 0.5s;

      &__classification {
        display: flex;
        justify-content: center;
        align-items: center;
        flex: 0 0 auto;
        height: 100%;
        width: 72px;
        padding-top: 2px;
        border-right: 1px solid $gray30;
        transition: border-color $cardTransitionTime linear 0.5s;
        .i-product-type {
          width: 100%;
          height: 100%;
          max-height: 40px;
          max-width: 40px;
          path,
          circle,
          line,
          polyline,
          rect {
            transition: stroke 0.25s linear 0.5s;
          }
        }
      }
      &__price {
        display: flex;
        align-items: center;
        flex: 1;
        padding-left: 22px;
        transition: color $cardTransitionTime linear 0.5s;
        @include price;
      }
    }
    &__details {
      flex: 1;
      padding: $spacer * 2 $spacer * 2.5 0;
      &__name {
        mix-blend-mode: color-burn;
        will-change: color;
        color: $charcoal;
        transition: color $cardTransitionTime linear 0.5s;
      }
      &__brand {
        color: $charcoal;
        opacity: 0.5;
        transition: color $cardTransitionTime linear 0.5s;
      }
    }
    &__discounts {
      padding: $spacer * 2 $spacer * 2.5 0;
      margin-bottom: -$spacer;
      transition: color $cardTransitionTime linear 0.5s;

      @include desktop {
        padding: $spacer * 2 $spacer * 2.5 $spacer;
        margin-bottom: 0;
      }
      .slug {
        margin-bottom: 0 !important;
        color: $deepblue;
        transition: color $cardTransitionTime linear 0.5s;
      }
    }
  }
  &__actions {
    padding: $spacer * 2 $spacer * 2.5;
    display: flex;
    &__cart {
      display: flex;
    }
    &__incart {
      @include body--small;
      color: $charcoal;
      padding-top: 2px;
      opacity: 0;
      transform: translateX(-10px);
      transition: color $cardTransitionTime linear 0.5s;
      &--enter-done,
      &--appear-done {
        opacity: 0.5;
        transform: translateX(0);
      }
      &--appear-active,
      &--enter-active {
        opacity: 0.5;
        transition: transform 0.25s ease-in-out 0.5s, opacity 0.25s linear 0.5s;
        transform: translateX(0);
      }
    }
    &__out-of-stock {
      @include body--small;
      color: $charcoal;
      opacity: 0.3;
    }
  }
  &__action {
    padding: 0;
    margin-right: $spacer * 1;
    svg {
      path,
      circle {
        transition: all $cardTransitionTime linear 0.5s;
      }
    }
    &:hover {
      svg {
        circle {
          fill: $charcoal;
          stroke: $charcoal !important;
        }
        path {
          fill: $white;
        }
      }
    }
  }

  // @include desktop {
  // }

  &:not(.c-product-card--no-feelings) {
    @include activeCard;
    &:hover {
      @include activeCardHover;
    }
  }

  &--active {
    @include activeCard;
    @include activeCardHover;
    .c-card-gradient {
      opacity: 1;
      transition: opacity 0.5s ease-in-out 0s !important;
      &__placeholder {
        opacity: 0;
        transition: opacity 0.5s ease-in-out 0s !important;
      }
    }
    .c-product-card {
      &__content {
        &__details {
          &__name,
          &__brand {
            transition: all $cardTransitionTime 0s;
          }
        }
        &__top-section {
          &__price {
            transition: all $cardTransitionTime 0s;
          }
          &__classification {
            .i-product-type {
              path,
              circle,
              line,
              polyline,
              rect {
                transition: all $cardTransitionTime 0s !important;
              }
            }
          }
        }
      }
      &__actions {
        &__incart,
        &__price {
          transition: all $cardTransitionTime 0s;
        }
      }
      &__action {
        svg {
          circle {
            transition: all $cardTransitionTime 0s !important;
          }
          path {
            transition: all $cardTransitionTime 0s !important;
          }
        }
      }
    }
  }
  .c-product-card__content__details__cbdContent {
    margin-top: 10px;
    margin-bottom: 10px;
    .slug {
      transform: normal;
      font-size: 14px;
    }
    span {
      font-weight: 400;
    }
  }
  .c-product-card__content__details {
    &__top {
      .c-product-card__content__details__name {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 3;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-bottom: 4px;
      }
      min-height: 87px;
    }
  }
}
