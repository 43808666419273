@import 'sass/utils/colors';
@import 'sass/utils/vars';
.c-package {
    padding-top: 0 !important;
    margin: $spacer * 5 auto;
    transition: opacity 1s linear;
    display: flex;
    flex-wrap: wrap;
    &__lab-results{
      background: $white;
      padding:20px;
      margin: 0 15px $spacer * 5;
      width:calc(50% - 30px);
  }

  &__topper-wrapper{
    background: $white;
    width:calc(50% - 15px);
    margin: 0 15px $spacer * 5 0;
    padding: 20px;

    .c-pdp-topper{
        height: auto;
    }

    .c-pdp-description-image{
        margin:48px 0;
    }

  }

  &--visible {
    opacity: 1;
  }

  &-wrapper {
    min-height: 100vh;
  }

//   @media (max-height: 900px) {
//     min-height: 800px;
//   }

  @include below-tablet {
    .c-atc {
      margin-bottom: $spacer * 5;
    }
  }
  @include tablet {
    background: $cream;
    padding-top: 0 !important;

    .shadow {
      display: block;
    //   position: fixed;
      top: 48px;
      height: 20px;
      // box-shadow:0px 0px 50px rgba(0,0,0,0.35);
      z-index: 5;
      opacity: 0;
      transition: opacity 0.25s linear;
    }
    &--shadowed {
      .shadow {
        opacity: 1;
      }
    }
  }
  @include desktop {
    padding-bottom: $spacer * 20;
  }
  .l-maxwidth {
    max-width: 1100px;
  }
  &__inner {
    overflow: hidden;
  }

  .slug {
    color: $gray90;
    font-weight: 500;
  }
  &__card {
    // position: relative;
    z-index: 1;
    max-width: 520px;
    background: $white;
    // width: calc(100% + 64px);
    // margin: 0 -32px;
    // padding: 0 32px 64px;
    // border-radius: 0px 0px $spacer * 1.25 $spacer * 1.25;
    // .c-pdp-pickup {
    //   height: 40px;
    // }
    // .c-button {
    //   max-width: 100%;
    // }
    // @media screen and (max-width: 540px) {
    //   max-width: 100%;
    // }

    // @include tablet {
    //   position: relative;
    //   border-radius: $border-radius * 1.25;
    //   padding: $spacer * 5 $spacer * 5;
    //   border-radius: $spacer * 2.5;
    //   z-index: 2;
    //   box-shadow: 0px 20px 100px rgba(0, 0, 0, 0.125);
    //   max-width: 400px;
    //   float: none;
    //   margin: 0 auto;
    // }
    // @include desktop {
    //   top: $spacer * 8;
    //   max-width: 520px;
    //   float: right;
    //   margin-right: $spacer * 6;
    // }
    // @include below-tablet {
    //   margin-left: auto;
    //   margin-right: auto;
    //   padding-left: $spacer * 2;
    //   padding-right: $spacer * 2;
    // }
  }
  &__topper-wrapper {
    // @include tablet {
    //   position: absolute;
    //   width: calc(100% - #{$spacer * 4});
    //   max-width: 1100px;
    //   left: 50%;
    //   transform: translateX(-50%);
    //   z-index: 0;
    // }
    // @include desktop {
    //   position: fixed;
    //   top: $spacer * 8.5;
    // }
    // @media (min-width: #{$desktop-width}) and (max-height:640px) {
    //   position: absolute;
    // }
  }
  &__back-to-top {
    clear: both;
    position: relative;
    display: flex;
    justify-content: center;
    width: calc(100% + 64px);
    margin: (-$spacer * 2.5) -32px 0;
    padding: $spacer * 6.5 32px $spacer * 4;
    border-radius: 0px 0px;
    z-index: 0;
    @include mobile-landscape {
      margin: 0 -32px 0;
    }
    @include tablet {
      display: none;
    }
  }
}

.c-package-nodata{
    padding:  $spacer * 6.5
}
