@import "sass/utils/vars";
@import "sass/utils/colors";
@import "sass/utils/typemixins";
.c-page {
  &__content {
    margin-top: $spacer * 5;
  }
  h2,
  h3,
  h4,
  h5 {
    margin-bottom: $spacer * 1.5;
  }
  h3 {
    margin-top: $spacer * 5;
    // font-size:$spacer*3;
  }
  p,
  ul,
  ol {
    margin: 0 0 $spacer * 2;
    @include body--small;
    a {
      color: $deepblue;
    }
  }
  ul,
  ol {
    padding-left: 0;
    list-style-position: outside;
    margin-left: $spacer * 2.5;
    ul {
      padding-left: $spacer * 3;
      list-style-position: outside;
    }
  }
  &__faq {
    margin-bottom: $spacer * 5;
  }
  .c-button {
    margin-top: $spacer * 5;
  }
  &__link {
    word-break: break-all;
  }
}

.cookie-table {
  &,
  th,
  td {
    border: 1px solid black;
    border-collapse: collapse;
  }
  th,
  td {
    padding: 7px;
    text-align: left;
    vertical-align: top;
  }
}
