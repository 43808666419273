@import 'sass/utils/vars';
@import 'sass/utils/colors';
@import 'sass/utils/typemixins';

.c-back-button{
  display:flex;
  padding:($spacer*3) 0;
  margin-left:(-$spacer);
  align-items:center;
  @include desktop{
    padding:$spacer*2 0;
  }
  span{
    @include body--small;
    color:$gray90;
  }
}