@font-face {
  font-family: 'Sneak';
  src:  url('./Sneak/Sneak-Regular.woff2') format('woff2'), 
        url('./Sneak/Sneak-Regular.woff') format('woff'),
        url('./Sneak/Sneak-Regular.eot') format('eot'),;
  font-style: normal;
  font-weight:normal;
}

@font-face {
  font-family: 'Sneak';
  src:  url('./Sneak/Sneak-Regular-Italic.woff2') format('woff2'), 
        url('./Sneak/Sneak-Regular-Italic.woff') format('woff'),
        url('./Sneak/Sneak-Regular-Italic.eot') format('eot'),;
  font-style: italic;
  font-weight: normal;
}

@font-face {
  font-family: 'Sneak';
  src:  url('./Sneak/Sneak-Medium.woff2') format('woff2'), 
        url('./Sneak/Sneak-Medium.woff') format('woff'),
        url('./Sneak/Sneak-Medium.eot') format('eot');
  font-style: normal;
  font-weight:500;
}

@font-face {
  font-family: 'Sneak';
  src:  url('./Sneak/Sneak-Medium-Italic.woff2') format('woff2'), 
        url('./Sneak/Sneak-Medium-Italic.woff') format('woff'),
        url('./Sneak/Sneak-Medium-Italic.eot') format('eot');
  font-style: italic;
  font-weight: 500;
}

@font-face {
  font-family: 'Sneak';
  src:  url('./Sneak/Sneak-Light.woff2') format('woff2'), 
        url('./Sneak/Sneak-Light.woff') format('woff'),
        url('./Sneak/Sneak-Light.eot') format('eot');
  font-style: normal;
  font-weight:300;
}

@font-face {
  font-family: 'Sneak';
  src:  url('./Sneak/Sneak-Light-Italic.woff2') format('woff2'), 
        url('./Sneak/Sneak-Light-Italic.woff') format('woff'),
        url('./Sneak/Sneak-Light-Italic.eot') format('eot');
  font-style: italic;
  font-weight: 300;
}

@font-face {
  font-family: 'Sneak';
  src:  url('./Sneak/Sneak-Bold.woff2') format('woff2'), 
        url('./Sneak/Sneak-Bold.woff') format('woff'),
        url('./Sneak/Sneak-Bold.eot') format('eot');
  font-style: normal;
  font-weight:700;
}

@font-face {
  font-family: 'Sneak';
  src:  url('./Sneak/Sneak-Bold-Italic.woff2') format('woff2'), 
        url('./Sneak/Sneak-Bold-Italic.woff') format('woff'),
        url('./Sneak/Sneak-Bold-Italic.eot') format('eot');
  font-style: italic;
  font-weight: 700;
}

@font-face {
  font-family: 'Sneak';
  src:  url('./Sneak/Sneak-Black.woff2') format('woff2'), 
        url('./Sneak/Sneak-Black.woff') format('woff'),
        url('./Sneak/Sneak-Black.eot') format('eot');
  font-style: normal;
  font-weight:900;
}

@font-face {
  font-family: 'Sneak';
  src:  url('./Sneak/Sneak-Black-Italic.woff2') format('woff2'), 
        url('./Sneak/Sneak-Black-Italic.woff') format('woff'),
        url('./Sneak/Sneak-Black-Italic.eot') format('eot');
  font-style: italic;
  font-weight: 900;
}

@font-face {
  font-family: 'Sneak Mono';
  src:  url('./Sneak/Sneak-Mono.woff2') format('woff2'), 
        url('./Sneak/Sneak-Mono.woff') format('woff'),
        url('./Sneak/Sneak-Mono.eot') format('eot');
  font-style: normal;
  font-weight: normal;
}