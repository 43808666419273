@import "sass/utils/vars";
@import "sass/utils/colors";
.c-page-wrapper--home {
  background: $cream;
}
.c-home {
  background: $cream;
  min-height: calc(100vh - 152px);
  display: flex;
  flex-direction: column;
  position: relative;

  &__spacer {
    height: $spacer * 10;
    width: 100%;
  }
  .c-page-wrapper--kiosk & {
    height: calc(100vh - 152px);

    @include desktop {
      height: calc(100vh - 160px);
      min-height: calc(100vh - 160px);
      //   height: auto;
    }
    @media (max-height: 991px) {
      height: auto;
    }
  }
}
