@import "sass/utils/vars";
@import "sass/utils/colors";
@import "sass/utils/typemixins";

.c-signin-form {
  margin-top: $spacer * 5;
  .form-control {
    margin-bottom: $spacer;
    input {
      width: calc(100% - #{$spacer * 2});
    }
  }
  .c-button--login {
    margin-top: $spacer * 5;
  }
  &__forgot {
    @include cta--text;
    text-decoration: underline;
    margin-left: $spacer * 2.25;
  }
  .checkbox__input {
    margin-top: $spacer * 3;
    .form-control__styled-checkbox {
      flex: 0 0 auto;
      margin-top: $spacer/2;
      // width:$spacer*3;
      // height:$spacer*3;
      // margin-right:$spacer*2;
    }
    .form-control__checkbox-label {
      @include body--small;
      a {
        text-decoration: underline;
      }
    }
  }
}
