@import 'sass/utils/vars';
@import 'sass/utils/typemixins';
.c-id-settings{
  h1{
    max-width:450px;
    margin-left:auto;
    margin-right:auto;
  }
  .c-button{
    margin-top:$spacer;
    &[type="submit"]{
      margin-top:$spacer*5;
    }
  }
  p.body--xsmall{
    margin:$spacer*3 0 $spacer;
  }
}