@import "sass/utils/colors";

@mixin loading-animation {
  background: $gray30 !important;
  animation: pulse 3s infinite ease-in-out;
}
.c-loading {
  &--product-card {
    @include loading-animation;
    box-shadow: none !important;
    .c-inventory-grid--transitioning & {
      opacity: 0;
      transition: opacity 0.25s linear;
    }
  }

  &--order-item {
    @include loading-animation;
  }

  &--order-heading {
    text-align: center;
  }

  &--loading-type {
    &--h3 {
      @include loading-animation;
      height: 22px;
    }
    &--slug {
      height: 15px;
      @include loading-animation;
      max-width: 150px;
    }
  }
  &--dispensary-location {
    .body--xsmall {
      @include loading-animation;
      height: 14px;
      margin: 2px 0;
      width: 80%;
      animation-delay: 0.2s;
      animation-duration: 2.75s;
    }
    .c-dispensary-location__figure {
      @include loading-animation;
      animation-delay: 0.5s;
      animation-duration: 2.5s;
    }
  }

  &--order-history-order {
    .c-order-history-order__dispensary {
      span,
      strong {
        @include loading-animation;
        height: 24px;
        margin-bottom: 3px;
      }
      strong {
        max-width: 60%;
      }
    }
    .c-order-history-order__item__name,
    .c-order-history-order__item__vendor {
      height: 13px;
      margin-bottom: 2px;
      @include loading-animation;
      max-width: 55%;
    }

    .c-order-history-order__item__name {
      max-width: 45%;
    }
    .c-order-history-order__item__meta,
    .c-order-history-order__item__price {
      height: 11px;
      margin-bottom: 2px;
      @include loading-animation;
      width: 20px;
    }
    .c-order-totals__row__price {
      height: 18px;
      margin-bottom: 4px;
      width: 30px;
      @include loading-animation;
    }
  }
}

@-webkit-keyframes pulse {
  0% {
    opacity: 0.5;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.5;
  }
}

@keyframes pulse {
  0% {
    opacity: 0.5;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.5;
  }
}
