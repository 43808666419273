@import 'sass/utils/vars';
@import 'sass/utils/colors';
.c-kiosk-timer{
  position:fixed;
  top:0;
  bottom:0;
  left:0;
  right:0;
  transform:translateY(-100%);
  opacity:0;
  background:$cream;
  z-index:555555;
  display:flex;
  justify-content:center;
  align-items:center;
  &--enter-active,
  &--enter-done,
  &--exit{
    opacity:1;
    transform:translateY(0);
  }
  &--exit-done,
  &--exit-active{
    transform:translateY(-100%);
    opacity:0;
  }
  &--enter-active,
  &--exit-active{
    transition:all 1s ease-in-out;
  }
  &__inner{
    width:100%;
    max-width:600px;
    display:flex;
    flex-direction:column;
    align-items:center;
    text-align:center;
  }
  &__time-remaining{
    font-size:150px;
    color:$gray90;
    line-height:1;
    margin-bottom:$spacer*5;
    text-align:center;
  }
  .c-button{
    margin-bottom:$spacer;
  }
}