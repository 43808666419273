@import "sass/utils/vars";
@import "sass/utils/colors";
@import "sass/utils/typemixins";
@mixin prevStep {
  opacity: 0;
  transform: translateX(-20px);
}
.c-mood-selector {
  background-size: cover;
  background-position: center center;
  width: 100%;
  min-height: calc(100vh - 62px);
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 0;
  overflow: hidden;
  opacity: 0;
  transition: all 1s ease-in-out 0.5s, opacity 0.25s linear 0.5s,
    margin-bottom 0.5s linear 1.5s;
  @include tablet-all-orientation {
    min-height: calc(100vh - 100px);
  }
  &--visible {
    opacity: 1;
  }

  .c-mood-selector__step--1 {
    height: calc(100vh - #{$spacer * 8.5});
    @include desktop {
      height: 100vh;
    }
  }

  &--relative-step {
    &-1 {
      &.c-mood-selector--open {
        .c-mood-selector__step__inner {
          // margin-bottom: 180px;
          padding-bottom: 0;
          padding-top: 0;
          .c-mood-selector__mood-names {
            // padding-top: $spacer * 6;
            // padding-bottom: $spacer * 6;
          }
        }
        @include desktop {
          margin-top: 90px;
          height: calc(100vh - 90px) !important;
          min-height: 600px !important;
        }
        .c-mood-selector__step--1 {
          // height: calc(100vh - 90px);
          height: calc(100vh - 250px);
          min-height: calc(100vh - 250px);
          @include desktop {
            min-height: 435px;
          }
        }
        @media screen and (max-height: 550px) {
          .c-mood-selector__step--1 {
            min-height: 400px !important;
            margin-bottom: 150px;
          }
        }
      }
      .c-mood-selector__step--1 {
        position: relative;
      }
    }
    &-2 {
      .c-mood-selector__step--2 {
        position: relative;
      }
    }
    &-3 {
      .c-mood-selector__step--3 {
        position: relative;
      }
    }
    &-4 {
      .c-mood-selector__step--4 {
        position: relative;
      }
    }
    &-5 {
      .c-mood-selector__step--5 {
        position: relative;
      }
    }
    &-6 {
      .c-mood-selector__step--6 {
        position: relative;
      }
    }
  }
  &__header {
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    text-align: center;

    // @include tablet {
    //   align-items: center;
    // }
  }
  &__question-progress {
    font-size: 14px;
    line-height: 140%;
    color: $gray80;
    margin-top: 8px;
    @include tablet {
      margin-top: 15px;
    }

    .c-menu-drawer & {
      display: none;
    }
  }
  &__step {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    display: flex;
    flex-direction: column;
    opacity: 1;
    transform: translateX(0);
    &__inner {
      .l-main {
        min-height: 0;
      }
    }
    .c-accordion__content & {
      position: relative !important;
      width: 100%;
      height: auto;
      opacity: 1 !important;
      left: 0 !important;
      visibility: visible !important;
      transform: translate(0) !important;
      min-height: 0;
      .c-mood-selector__step__inner {
        padding: 0;
        .l-main {
          padding: 0 !important;
          min-height: 0;
        }
        h1 {
          display: none;
        }
        .c-mood-selector__sliders,
        .c-mood-selector__activities,
        .c-mood-selector__occasions,
        .c-mood-selector__formats {
          margin-top: 0;
        }
        .c-mood-selector__leftovers {
          .c-button {
            margin-top: $spacer * 2;
          }
        }
        .c-mood-selector__activity,
        .c-mood-selector__occasion {
          background: $white !important;
          &.c-mood-selector__activity--selected,
          .c-mood-selector__occasion--selected {
            border: 1px solid $gray20;
          }
        }
        .c-mood-selector__format {
          height: $spacer * 22.5;
          &:not(.c-mood-selector__format--selected) {
            background: $white !important;
          }
          &__icon {
            width: $spacer * 12;
            height: $spacer * 12;
          }
        }
        .c-slider {
          padding: $spacer * 2 $spacer * 2.5 $spacer * 4;
          margin-bottom: 0;
          .c-slider__labels {
            left: 0;
            right: 0;
            bottom: 0;
          }
        }
      }
    }
    &__inner {
      display: flex;
      flex-direction: column;
      flex: 1;
    }
    @include tablet {
      width: 100%;
      height: 100%;
    }
    @include desktop {
      min-height: 700px;
    }
  }
  &--step-1 {
    .c-page-wrapper--kiosk & {
      max-width: calc(100% - #{$spacer * 6});
      max-height: calc(100vh - 125px);
    }
  }
  &--step-2,
  &--step-3,
  &--step-4,
  &--step-5,
  &--step-5_5,
  &--step-6 {
    .c-mood-selector__step--1 {
      @include prevStep;
    }
  }
  &--step-3,
  &--step-4,
  &--step-5,
  &--step-5_5,
  &--step-6 {
    .c-mood-selector__step--2 {
      @include prevStep;
    }
  }
  &--step-4,
  &--step-5,
  &--step-5_5,
  &--step-6 {
    .c-mood-selector__step--3 {
      @include prevStep;
    }
  }
  &--step-5,
  &--step-5_5,
  &--step-6 {
    .c-mood-selector__step--4 {
      @include prevStep;
    }
  }

  &--step-5_5 {
    .c-mood-selector__step--5 {
      @include prevStep;
    }
  }

  &--step-6 {
    .c-mood-selector__step--5,
    .c-mood-selector__step--5_5 {
      @include prevStep;
    }
  }
  &:not(.c-mood-selector--open) {
    .c-page-wrapper--recommendations & {
      .c-mood-selector {
        &__drag-area {
          bottom: 32px;
        }
        &__mood-names {
          @include desktop {
            padding-bottom: 64px;
          }
        }
      }
    }
  }
  &--open {
    &.c-mood-selector--visible {
      @include below-tablet {
        min-height: calc(100vh - 60px) !important;
      }
      @media screen and (max-height: 550px) {
        min-height: 400px !important;
        max-height: inherit !important;
      }
    }
    .c-mood-selector__step__inner {
      position: relative;
      margin-top: 25px;
    }
    .c-mood-selector {
      &__actions {
        opacity: 0;
        pointer-events: none;
        transition: opacity 0.25s linear 0s;
      }
      &__figure {
        @include below-tablet {
          // top: $spacer * 11;
          // bottom: $spacer * 21;
          // left: $spacer * 3;
          // right: $spacer * 3;
        }
        @include tablet {
          // top: calc(50vh - 350px);
          // bottom: calc(50vh - 250px);
          // left: 0;
          // right: 0;
          .c-page-wrapper--kiosk & {
            top: calc(25vh - 150px);
            bottom: calc(25vh - 250px);
            left: calc(50% - 350px);
            right: calc(50% - 350px);
          }
        }
        @include desktop {
          // top: calc(50vh - 220px);
          // bottom: calc(50vh - 220px);
          left: calc(50vw - 550px);
          right: calc(50vw - 550px);

          .c-page-wrapper--kiosk & {
            top: $spacer * 21;
            bottom: $spacer * 11;
            left: $spacer * 3;
            right: $spacer * 3;
          }
        }
        @media screen and (max-width: 1200px) {
          left: $spacer * 3;
          right: $spacer * 3;
        }
        .bg-rect {
          height: 100vh !important;
          width: 100vw !important;
        }
      }
      &__drag-area {
        // top: $spacer * 12;
        // bottom: $spacer * 2.5;
        // left: $spacer * 4;
        // right: $spacer * 4;
        top: $spacer * 2;
        bottom: $spacer * 2;
        left: $spacer * 2;
        right: $spacer * 2;
        @include tablet {
          // top: calc(50vh - 328px);
          // bottom: calc(50vh - 218px);
          left: 32px;
          right: 32px;
          .c-page-wrapper--kiosk & {
            // top: calc(25vh - 130px);
            // bottom: 0;
            top: 50px;
            bottom: 50px;
            left: calc(50% - 315px);
            right: calc(50% - 315px);
          }
        }
        @include desktop {
          // top: calc(50vh - 240px);
          // bottom: calc(50vh - 170px);
          top: 50px;
          bottom: 50px;
          left: $spacer * 6;
          right: $spacer * 6;

          .c-page-wrapper--kiosk & {
            top: calc(50vh - 205px);
            bottom: calc(50vh - 205px);
            left: $spacer * 7;
            right: $spacer * 7;
          }
        }
        transition: all 0s linear 0.25s, opacity 0.25s linear 0s;
        // left:calc(50vw - 550px);
        // right:calc(50vw - 550px);
      }
      &__drag-knob {
        // opacity:0;
        // pointer-events:none;
      }
      &__mood-names {
        transition: all 1s ease-in-out 0.5s;
        min-height: 0;
        // padding-top: $spacer * 13.5;
        padding-top: $spacer * 5;
        padding-bottom: $spacer * 4;
        padding-left: $spacer * 5;
        padding-right: $spacer * 5;

        @include below-tablet {
          max-height: 100%;
          // padding-bottom: $spacer * 1.2;
          // padding-left: $spacer * 7.5;
          // padding-right: $spacer * 7.5;
        }
        @include tablet {
          // padding-top: calc(50% - 200px);
          // padding-bottom: calc(50% - 120px);
        }
        @include tablet-only {
          // padding-left: calc(50% - 240px);
          // padding-right: calc(50% - 240px);
        }
        @include desktop {
          // padding-top: calc(50vh - 240px);
          // padding-bottom: calc(50vh - 170px);
        }
        &__text {
          transition: opacity 0.5s ease-in-out;
          opacity: 0;
        }
      }
      &__open-actions {
        transform: translateY(0);
        opacity: 1;
        pointer-events: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        @include below-tablet {
          .c-button {
            max-width: 314px;
          }
        }
      }
      &__open-title {
        opacity: 1;
        transform: translate(0, 0);
        position: static;
        @include below-tablet {
          padding: 0 $spacer * 3;
          // top: $spacer * 8;
          h1 {
            @include h3;
            width: 100%;
            text-align: center;
          }
        }
        @include tablet-only {
          // top: 75px;
          .c-page-wrapper--kiosk & {
            top: 75px;
          }
        }
        @include tablet {
          // top: 120px;
          h1 {
            width: 100%;
            text-align: center;
          }
        }

        @include desktop {
          // top: calc(50vh - 240px);
        }
      }
    }
  }
  &--opening {
    .c-mood-selector {
      &__open-title,
      &__open-actions {
        transition: all 0.75s ease-in-out 1.75s;
      }
    }
  }
  &--going-prev {
    .c-mood-selector {
      &__open-title,
      &__open-actions {
        transition: all 0s ease-in-out 0s !important;
      }
    }
  }
  &__sliders,
  &__activities,
  &__occasions,
  &__formats {
    margin-top: $spacer * 3;
    margin-bottom: 0;
    @include tablet {
      margin-top: $spacer * 3;
    }
  }
  &__formats {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    width: calc(100% + #{$spacer});
    margin-left: -$spacer/2;
    margin-right: -$spacer/2;
  }
  &__format {
    position: relative;
    flex: 0 0 auto;
    width: calc(50% - #{$spacer});
    height: 180px;
    margin: 0 $spacer/2;
    @include tablet {
      width: calc(33.3333% - #{$spacer});
      height: 230px;
    }
    border-radius: $border-radius;
    border: 1px solid $gray20;
    margin-bottom: 15px;
    background-color: $cream;
    transition: background-color 0.25s linear;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    &__icon {
      height: 75px;
      width: 75px;
      @include desktop {
        height: 124px;
        width: 124px;
      }
      margin-bottom: $spacer * 2;
      margin-top: $spacer * 2;
      div,
      svg {
        object-fit: contain;
        height: 100%;
        width: 100%;
        object-position: center;
      }
    }
    span {
      color: $charcoal;
      font-size: 16px;
    }
    .checkmark-icon {
      position: absolute;
      top: $spacer * 2;
      right: $spacer * 1.5;
      transition: opacity 0.25s linear;
      opacity: 0;
    }
    &--disabled {
      pointer-events: none;
      svg,
      span {
        opacity: 0.1;
      }
    }
    &--selected {
      background-color: $deepblue;
      .checkmark-icon {
        opacity: 1;
      }
      span {
        color: $white;
      }
      .c-mood-selector__format__icon {
        rect,
        polyline,
        path,
        line,
        circle,
        polygon {
          stroke: $white !important;
        }
      }
    }
  }
  &__activity,
  &__occasion {
    margin-bottom: $spacer * 2;
    width: 100%;
    height: 54px;
    display: flex;
    align-items: center;
    padding: 0 $spacer * 3;
    background-color: $cream;
    border-radius: $border-radius;
    transition: all 0.25s linear, box-shadow 0.25s linear 0.25s;
    border: 1px solid $gray30;
    box-shadow: none;
    text-align: left;
    font-size: 16px;
    &:focus {
      outline: none;
    }
    &:last-of-type {
      margin-bottom: 0;
    }
    &--selected {
      background-color: $white;
      box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.1);
      border-color: $white;
      .c-mood-selector__activity__check,
      .c-mood-selector__occasion__check {
        background-color: $deepblue;
        svg {
          opacity: 1;
        }
      }
    }
    span {
      flex: 1;
    }
    &__check {
      height: $spacer * 2.25;
      width: $spacer * 2.25;
      border-radius: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 1px solid $gray30;
      transition: background-color 0.25s linear;
      svg {
        opacity: 0;
        transition: opacity 0.25s linear;
      }
    }
  }

  &__action {
    &-appear-active,
    &-appear-done,
    &-enter-active,
    &-exit {
      opacity: 0;
      transition: opacity 0.25s linear;
    }

    &-enter-done {
      opacity: 1;
    }
  }

  &__standard-action {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 2;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: $cream;
    transform: translateY(calc(100% + 20px));
    transition: transform 0.5s ease-in-out;
    padding: $spacer * 3.5 $spacer * 3 $spacer * 2;

    .c-menu-drawer & {
      display: none;
    }

    &__step {
      position: static;
      transform: translateY(0);
      padding-top: 0;
    }
    @include desktop {
      flex-direction: column;
    }
    .c-button {
      margin-bottom: $spacer;
    }
    &--visible {
      transform: translateY(0);
      transition: transform 0.5s ease-in-out 0.5s;
      &--1 {
        transition: transform 0.5s ease-in-out 1.5s;
      }
    }
    .c-page-wrapper--kiosk & {
      position: fixed;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 2;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      background: $cream;
      box-shadow: 0px -10px 10px rgba(0, 0, 0, 0.03);
      transform: translateY(calc(100% + 20px));
      transition: transform 0.5s ease-in-out;
      padding: $spacer * 3.5 $spacer * 3 $spacer * 2;
      .c-button {
        margin-bottom: $spacer;
      }
      &--visible {
        transform: translateY(0);
        transition: transform 0.5s ease-in-out 0.5s;
        &--1 {
          transition: transform 0.5s ease-in-out 1.5s;
        }
      }
    }
  }
  &__open-title {
    // position: absolute;
    // top: calc(50vh - 270px);
    width: 100%;
    display: flex;
    justify-content: center;
    transform: translate(0, -100%);
    position: absolute;
    opacity: 0;
    pointer-events: none;
    z-index: 1;
    transition: all 0.25s linear;
    .c-page-wrapper--kiosk & {
      top: 90px;
    }
    &--visible {
      transition: all 0.25s linear 1.5s;
    }
    @include below-tablet {
      padding: 0 $spacer * 3;
      // top: $spacer * 4;
      // justify-content: flex-start;
    }
    @include tablet-all-orientation {
      // top: 50px;
    }
  }
  &__open-actions {
    position: absolute;
    top: calc(50vh + 260px);
    left: 0;
    width: 100%;
    transform: translate(-50%, 0);
    display: flex;
    flex-direction: column;
    align-items: center;
    transform: translateY(10px);
    opacity: 0;
    pointer-events: none;
    z-index: 1;
    .c-button {
      margin-bottom: $spacer;
    }
    z-index: 10;
    @include tablet-only {
      top: calc(50% + 298px);
    }
    @include desktop {
      top: calc(50vh + 252px);
    }
  }
  &__gradient {
    rect {
      border-radius: 100%;
      overflow: hidden;
      transition: fill 0.5s linear;
      -moz-transition: fill 0.5s linear;
      will-change: fill;
    }
    stop {
      transition: stop-color 0.75s linear;
      -moz-transition: stop-color 0.75s linear;
      will-change: stop-color;
    }
  }
  &__click-ghost {
    position: absolute;
    top: calc(50% + 63px);
    left: 50%;
    transform: translate(-50%, -50%);
    background: transparent;
    height: 52px;
    width: 314px;
    border-radius: 8px;
    cursor: pointer;

    @include tablet {
      display: block;
    }

    @include desktop {
      top: calc(50% + 73px);

      .c-page-wrapper--recommendations & {
        top: calc(50% + 82px);
      }
    }
  }

  &__noise {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    object-fit: cover;
    z-index: 3;
  }
  &__figure {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 0;
    overflow: hidden;
    opacity: 0;
    transition: all 1s ease-in-out 0.5s, opacity 0.25s linear 0.5s;
    .c-page-wrapper--kiosk & {
      border-radius: $border-radius;
    }
    &--visible {
      opacity: 1;
    }
    .bg-rect {
      transition: all 1s ease-in-out 0.5s;
    }
    .testing-test {
      fill: url("#moodgradient1");
    }
    svg {
      position: relative;
      z-index: 1;
      transform: translate3d(0, 0, 0);
      -webkit-backface-visibility: hidden;
      -webkit-perspective: 1000;
      // overflow:hidden;
      background: $white;
      transition: background-color 0.75s linear;
      * {
        transform: translate3d(0, 0, 0);
        -webkit-backface-visibility: hidden;
        -webkit-perspective: 1000;
      }

      .blur-me {
        display: flex;
        justify-content: center;
        margin: 0 auto;
        // filter:blur(40px);
        // -moz-filter:blur(40px);
      }
      @include below-tablet {
        // object-fit:cover;
      }

      // object-fit:cover;
      width: calc(100% + 200px);
      height: calc(100% + 200px);
      // object-fit:cover;
      margin-left: -100px;
      margin-top: -100px;
    }
  }
  @include desktop {
    min-height: 100vh;
  }
  .c-page-wrapper--kiosk & {
    padding: 0 $spacer * 3;
    @include below-desktop {
        padding: 0;
    }
    margin: 0 auto;
    border-radius: $border-radius;
    // max-height: calc(100vh - 450px);
    min-height: 0;
    max-height: inherit !important;
    flex: 1;

    &:not(.c-mood-selector--relative-step-1) {
      height: auto;
    }
    &.c-mood-selector--open {
      max-height: 100vh;
      height: auto;
      transition: max-height 1s linear 0.75s;
    }
  }
  .c-mood-selector__step--1 {
    // @media screen and (max-height: 480px) {
    @include below-tablet {
      min-height: calc(100vh);
    }
  }
  &__mood-names {
    position: relative;
    flex: 1;
    padding: $spacer * 6 $spacer * 4 $spacer * 3;
    display: flex;
    flex-direction: column;
    transition: all 1s ease-in-out 0.5s;

    //for view below desktop for landscape issue
    @media screen and (max-height: 500px) and (orientation: landscape) {
      min-height: calc(100vh);
    }

    @include tablet {
      padding: $spacer * 6 $spacer * 4 $spacer * 12;
    }
    @include desktop {
      padding: $spacer * 6 $spacer * 4;
    }
    @include desktop {
      // padding: $spacer * 15 $spacer * 10 $spacer * 13;
      padding: $spacer * 12 $spacer * 10 $spacer * 13;
      max-width: 1080px;
      margin: 0 auto;
      width: 100%;
    }

    &__inner {
      position: relative;
      flex: 1;
      @include slug;
      color: $white;
      text-transform: uppercase;
    }
    &__top-left {
      position: absolute;
      top: 0;
      left: -10px;
      opacity: 0.4;
      width: 85px;
      text-align: center;
      @include desktop {
        left: -5px;
      }
    }
    &__top-right {
      position: absolute;
      top: 0;
      right: -12px;
      opacity: 0.4;
      width: 85px;
      text-align: center;
      @include desktop {
        right: -10px;
      }
    }
    &__bottom-left {
      position: absolute;
      // bottom: 22px;
      bottom: 0;
      left: -10px;
      opacity: 0.4;
      width: 85px;
      text-align: center;
      @include desktop {
        left: -5px;
        bottom: 0;
      }
    }
    &__bottom-right {
      position: absolute;
      // bottom: 22px;
      bottom: 0;
      right: -12px;
      opacity: 0.4;
      width: 85px;
      text-align: center;
      @include desktop {
        right: -10px;
        bottom: 0;
      }
    }
    &__text {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: 38px;
      line-height: 39.6px;
      text-align: center;
      color: $white;
      width: 100%;
      text-transform: none;
      display: flex;
      flex-direction: column;
      align-items: center;
      font-weight: 300;
      transition: opacity 0.5s ease-in-out 1s;

      .c-button {
        margin-top: $spacer * 3;
      }

      @include tablet {
        font-size: 56px;
        line-height: 62px;
      }
      @include desktop {
        font-size: 60px;
        line-height: 60px;
      }
    }
  }

  &__drag-area {
    position: absolute;
    top: $spacer * 2;
    left: $spacer * 3;
    right: $spacer * 3;
    bottom: $spacer * 2;
    transition: all 0s linear 0.5s, opacity 0.25s linear 0s;
    opacity: 1;
    &--hidden {
      opacity: 0;
    }
    @include tablet {
      bottom: $spacer * 10;
    }
    @include desktop {
      top: 48px;
      left: 58px;
      right: 58px;
      bottom: 48px;
    }
  }

  &__drag-knob,
  &__drag-knob-wrapper {
    position: absolute;
    width: 80px;
    height: 80px;
    opacity: 0;
    transition: opacity 0.25s linear;
    transform-origin: left top;
    will-change: transform;
    -webkit-backface-visibility: hidden;
    -webkit-perspective: 1000;
    cursor: grab;
    svg {
      pointer-events: none;
    }

    &__labels {
      &--transition {
        .c-mood-selector__drag-knob__labels {
          &__new {
            opacity: 1;
            transition: opacity 0.25s linear 0.25s;
          }
          &__current {
            opacity: 0;
            transition: opacity 0.25s linear;
          }
        }
      }
      &__new,
      &__current {
        text-transform: uppercase;
        white-space: nowrap;
      }
      &__current {
        opacity: 1;
      }
      &__new {
        opacity: 0;
      }
    }
    &--visible {
      opacity: 1;
    }
    .c-page-wrapper--kiosk & {
      // top:50%;
    }
    &--no-arrows {
      .c-mood-selector__drag-knob {
        &__arrows {
          opacity: 0;
        }
      }
    }
    &__arrows {
      display: none;
      opacity: 0.5;
      transition: opacity 0.25s ease-in-out;
      @include tablet {
        display: block;
      }
      svg {
        width: $spacer * 5 !important;
        height: auto !important;
      }
      &--top {
        position: absolute;
        top: -$spacer * 5;
        left: 50%;
        transform: translateX(-50%);
        svg {
          transform: rotate(90deg);
        }
      }
      &--bottom {
        position: absolute;
        bottom: -$spacer * 5.5;
        left: 50%;
        transform: translateX(-50%);
        svg {
          transform: rotate(-90deg);
        }
      }
      &--right {
        position: absolute;
        right: -$spacer * 5;
        top: 50%;
        transform: translateY(-50%);
        svg {
          transform: rotate(180deg);
        }
      }
      &--left {
        position: absolute;
        left: -$spacer * 5;
        top: 50%;
        transform: translateY(-50%);
        svg {
          transform: rotate(0deg);
        }
      }
    }
    @include desktop {
      width: 116px;
      height: 116px;
    }
    &__circle {
      stroke-width: 2px;
      opacity: 0.5;
    }
    &:before {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      display: block;
      display: none;
      opacity: 0.5;
      border: 1px solid $white;
      border-radius: 100%;
    }
    &:after {
      content: "";
      height: $spacer * 0.75;
      width: $spacer * 0.75;
      border-radius: 100%;
      background: $white;
      position: absolute;
      top: 50%;
      left: 50%;
      margin-left: -3px;
      margin-top: -3px;
      @include desktop {
        height: $spacer;
        width: $spacer;
      }
    }
  }
  &__drag-knob-wrapper {
    opacity: 1;
    z-index: 9;
    &--disabled{
        opacity: 0.65;
        pointer-events: none;
    }
  }
  .c-button--find-cannabis {
    max-width: 314px;
    background: $white;
    color: $charcoal;
    &:focus {
      background: $white;
      color: $charcoal;
    }
  }
  &__color-preview {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: $spacer * 5;
    left: $spacer * 5;
  }
  &__color {
    height: $spacer * 10;
    width: $spacer * 10;
    border-radius: 100%;
  }
  &__actions {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 0 0 auto;
    padding-bottom: $spacer * 3;
    z-index: 2;
    opacity: 1;
    transition: opacity 0.25s linear 0.5s;



    @include below-desktop {
      padding-left: $spacer * 2;
      padding-right: $spacer * 2;
      padding-bottom: $spacer;
    }

    @media screen and (max-height: 500px) and (orientation: landscape) {
      margin-top: -80px;
    }

    @include tablet {
      display: none;
    }

    &__browsing-from {
      margin-top: $spacer * 2;
      font-size: 12px;
      color: $white;
      transform: translateY(5px);
      opacity: 0;
      transition: transform 0.75s ease-in-out 0s, opacity 0.5s linear 0.25s;
      max-width: 90%;
      display: flex;
      > span {
        flex: 0 0 auto;
      }
      &--located {
        transform: translateY(0);
        opacity: 1;
      }
      a {
        text-decoration: underline;
        color: $white;
        display: flex;
        align-items: center;
        margin-left: $spacer/2;
        span {
          flex: 1;
          display: inline-block;
          white-space: nowrap;
          text-overflow: ellipsis;
          max-width: calc(90vw - 100px);
          overflow: hidden;
        }
        svg {
          width: $spacer/2;
          margin-left: $spacer/2;
          flex: 0 0 auto;
        }
      }
    }
  }
  &__leftovers {
    position: relative;
    max-height: 68px;
    transition: max-height 0.5s linear 0.5s;
    z-index: 0;
    .c-button {
      margin: 0 auto $spacer * 2;
      // opacity: 0.25;
      opacity: 0.55;
      transition: opacity 0.25s linear;
      @include desktop {
        &:hover {
          opacity: 1;
        }
      }
    }
    &__sliders {
      opacity: 0;
      max-height: 0;
      visibility: hidden;
      transition: opacity 0.25s linear 0s, max-height 0s linear 0.5s,
        visibility 0s linear 0.5s;
    }
    &--open {
      max-height: 1500px;
      transition: max-height 0.5s linear 0s;
      .c-mood-selector__leftovers {
        &__sliders {
          max-height: 100%;
          opacity: 1;
          visibility: visible;
          transition: opacity 0.25s linear 0.25s, visibility 0s linear 0s;
        }
      }
    }
  }
  .c-places-search__wrapper {
    width: 100%;
  }

  &__canvas-wrapper {
    position: relative;
    width: 100%;
    height: 100%;
    transition: background-color 1.5s ease-in-out;
    .c-product-card & {
      height: 380px;
      width: 280px;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      transition: background-color 1.5s ease-in-out;
    }
    &--safari {
      canvas {
      }
      .c-mood-selector__canvas-gradient {
      }
    }
  }
  &__canvas-inner {
    position: relative;
    margin: 0 auto;
    height: 100%;
    .c-product-card & {
      height: 380px;
    }
    canvas {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
    }
  }

  &__canvas-gradient {
    .c-product-card & {
      width: 200px;
      height: 200px;
    }
    // transition:mix-blend-mode 0.25s linear;
    // mix-blend-mode:normal;
    &--2 {
      .c-product-card & {
        width: 150px;
        height: 150px;
        top: -20px;
      }
    }
  }
}

//If KIOSK
.c-page-wrapper--kiosk {
  .c-mood-selector--step-6 {
    padding: 0;
  }

  .c-mood-selector__mood-names {
    padding: $spacer * 6 $spacer * 4 0;
  }

  .c-mood-selector--open {
    .c-mood-selector__figure {
      @include desktop {
        border-radius: 0;
        // top: 130px;
        top: 25px;
        bottom: 20px;
        left: 0;
        right: 0;
      }
    }

    .c-mood-selector__mood-names {
      @include tablet {
        position: absolute;
        top: 110px;
        bottom: 0;
        left: 0;
        right: 0;
        padding: $spacer * 4.5;
      }

      @include desktop {
        position: absolute;
        max-width: none;
        top: 100px;
        bottom: 20px;
        left: 0;
        right: 0;
        padding: $spacer * 4.5;
      }
    }

    .c-mood-selector__drag-area {
      @include tablet {
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        padding: $spacer * 4.5;
      }
    }

    .c-mood-selector__step--1 {
      @include desktop {
        min-height: auto;
      }
    }
  }

  @include below-desktop {
    .c-mood-selector__drag-area {
      bottom: 0;
    }
  }

  .c-mood-selector__click-ghost {
    top: calc(50% + 78px);

    @include tablet-landscape {
      top: calc(50% + 69px);
    }
  }

  .c-recommended-products__container {
    width: calc(100% - 168px);
  }

  .c-mood-selector__standard-action {
    display: none;

    &--kiosk {
      display: flex;
      padding: 0;
      height: 160px;
    }
  }

  .c-mood-selector {
    overflow-y: hidden;

    &--open {
      overflow-y: auto;
      max-height: calc(100vh - 230px) !important;
      height: calc(100vh - 230px) !important;
    }
  }

  .c-mood-selector--open.c-mood-selector--step-1 {
    overflow: initial;
    max-height: calc(100vh - 250px) !important;
    height: calc(100vh - 250px) !important;
  }

  .c-mood-selector__step {
    max-height: 90vh;
    overflow: hidden;
    &.c-mood-selector__step--1 {
      max-height: calc(100vh - 250px) !important;
      min-height: 500px;
      @include below-desktop{
        max-width: 100%;
      }
    }
  }

  .c-mood-selector--step-2 .c-mood-selector__step--2,
  .c-mood-selector--step-3 .c-mood-selector__step--3,
  .c-mood-selector--step-4 .c-mood-selector__step--4,
  .c-mood-selector--step-5 .c-mood-selector__step--5,
  .c-mood-selector--step-6 .c-mood-selector__step--6 {
    max-height: none;
    overflow: visible;
  }

  .c-mood-selector__leftovers {
    overflow: hidden;

    &--open {
      overflow: visible;
    }
  }

  .c-mood-selector:not(.c-mood-selector--open) {
    max-height: none !important;
    // padding-bottom: 300px;
    padding-bottom: 240px;
  }
  @include desktop {
    .c-kiosk-options {
      &--selector-open {
        display: none;
      }
    }
  }
}
