@import 'sass/utils/colors';
@import 'sass/utils/vars';
.c-circle-arrow-button{
  position:relative;
  border-radius:100%;
  background:$white;
  display:flex;
  align-items:center;
  justify-content:center;
  height:$spacer*4.5;
  width:$spacer*4.5;
  padding:0;
  svg{
    width:18px;
    position:absolute;
    top:50%;
    left:50%;
    transform:translate(-50%,-50%);
  }
}