@import 'sass/utils/vars';
@import 'sass/utils/colors';
@import 'sass/utils/typemixins';
.c-mobile-nav{
  position:fixed;
  left:0;
  right:0;
  bottom:100%;
  top:0;
  background:$deepblue;
  transition:bottom 0.5s ease-in-out 0.5s;
  z-index:3;
  overflow-y:auto;

  @media (min-height: 760px) {
    display:flex;
    flex-direction:column;
    justify-content:flex-end;
    overflow:hidden;
  }
  
  @include desktop{
    display: none;
  }
  &__menu{
    position:relative;
    flex:1;
    border-radius: 0 0 $border-radius-desktop $border-radius-desktop;
    margin-bottom:-$spacer;
    background:$deepblue;
    z-index:1;
    padding:$spacer*10 $spacer*4;
    opacity:0;
    transition:opacity 0.5s linear;
    .c-header__location{
      width:100%;
      margin-left:0;
      &__button{
        margin-left:0;
        width:100%;
        max-width:100%;
        background:$white;
        height:$spacer*7;
        svg{
          path{
            stroke: $deepblue !important;
          }
        }
        .c-header__location__value{
          color:$charcoal !important;
        }
      }
    }
    &__option{
      @include h1;
      color:$white;
      display:block;
      margin-bottom:$spacer*2;
      &:last-of-type{
        margin-bottom:0;
      }
      &--profile{
        display:flex;
        align-items:center;
        img{
          width:$spacer*4;
          margin-right:$spacer*2;
        }
      }
      &--small{
        @include h3;
      }
    }
    &__options{
      margin-top:$spacer*3;
      padding:$spacer*3 0;
      border-top:1px solid rgba(255,255,255,0.2);
      border-bottom:1px solid rgba(255,255,255,0.2);
      margin-bottom:$spacer*3;
    }
  }
  &__profile-icon{
    position:relative;
    margin-right:$spacer;
    height:$spacer*3;
    width:$spacer*3;
    border-radius:100%;
    overflow:hidden;
  }
  footer{
    position:relative;
    transform:translateY(105%);
    transition:transform 0.5s ease-in-out 0s;
    z-index:-1;
    padding-top:$spacer*2.5;
    opacity: 0;
    &:before{
      content:'';
      display:block;
      position:absolute;
      top:0;
      left:0;
      right:0;
      height:20px;
      background:$deepblue;
      border-radius: 0 0 $border-radius-desktop $border-radius-desktop;
    }
  }
  &--open{
    bottom:0;
    transition:bottom 0.5s linear;
    .c-mobile-nav__menu{
      opacity:1;
      transition:opacity 0.5s linear 0.5s;
    }
    footer{
      opacity: 1;
      transform:translateY(0);
      transition:transform 0.5s ease-in-out 0.5s;
    }
  }
}
