@import "sass/utils/vars";
.c-inventory-grid {
  margin-top: $spacer * 5;
  opacity: 1;
  transition: opacity 0.25s linear 0.25s;
  &--transitioning {
    opacity: 0;
    transition: opacity 0.25s linear;
  }
  &__no-results {
    opacity: 0;
    height: 250px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transition: opacity 0.25s linear;
  }
  &--no-results {
    .c-inventory-pagination {
      display: none;
    }
    .c-inventory-grid {
      &__no-results {
        opacity: 1;
        transition: opacity 0.25s linear 0.5s;
      }
    }
  }
  @include desktop {
    &__inner {
      max-width: 1400px;
      min-height: calc(100vh - 525px);
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      margin: $spacer * 5 auto;
    }
    // width:calc(100% + #{$spacer*5});
    // margin:$spacer*5 (-$spacer*2.5);
  }
  .c-product-card {
    margin-bottom: $spacer * 3;
    @include desktop {
      margin: $spacer * 2.5;
      width: 280px;
      height: 385px;
    }
  }
}
