@import 'sass/utils/vars';
@import 'sass/utils/colors';
@import 'sass/utils/typemixins';

.c-pdp-good-for {
  margin-top: $spacer*6;
  @include tablet {
    margin-bottom: $spacer*10;
  }

  &__measurement {
    margin-top: $spacer*3;
  }

  &__topper {
    display: flex;
    width: 100%;
    justify-content: space-between;
  }

  &__progress {
    width: 100%;
    background: $gray20;
    height: 4px;
    margin-top: $spacer;
    border-radius: 4px;

    &__inner {
      position: relative;
      width: 100%;
    }

    &__indicator {
      position: absolute;
      top: 0;
      left: 0;
      width: 35px;
      height: 4px;
      background: $charcoal;
      transition: transform 1.25s ease-in-out 0.25s, opacity 1.25s linear 0.25s;
    }
  }
}