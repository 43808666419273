@import 'sass/utils/colors';
@import 'sass/utils/vars';
.c-button{
  display:flex;
  justify-content:center;
  align-items:center;
  border:0;
  outline:0;
  background:$charcoal;
  color:#fff;
  width:100%;
  height:52px;
  opacity:1;
  padding-top:1px;
  transition:opacity 0.25s linear, box-shadow 0.5s linear;
  border-radius:$border-radius;
  cursor:default;
  &:not([disabled]){
    cursor:pointer;
  }
  @include desktop{
    &:focus{
      background-color:#000 !important;
      color:$darkcream;
      box-shadow: 0px 10px 20px rgba(0,0,0,0.25);
    }
  }
  &--outlined{
    background:transparent;
    border:1px solid $charcoal;
    color:$charcoal;
  }
  &--outlined-white{
    background:transparent;
    border:1px solid $white;
    color:$white;
  }
  @include tablet{
    max-width:314px;
  }

  &:disabled{
    opacity:0.25;
  }
}
