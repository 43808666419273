@mixin price {
  font-weight: 500;
  font-size: 16px;
  line-height: 130%;
  @include desktop {
    font-size: 16px;
  }
}

@mixin h1 {
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 110%;
  margin: 0;
  @include desktop {
    font-size: 40px;
  }
  &.t-page-title {
    @include desktop {
      font-size: 90px;
    }
  }
}

@mixin h2 {
  font-size: 28px;
  line-height: 110%;
  font-weight: 400;
  @include desktop {
    font-size: 40px;
    line-height: 48px;
  }
}

@mixin h3 {
  font-size: 20px;
  line-height: 110%;
  @include desktop {
    font-weight: 500;
  }
}

@mixin body {
  font-size: 16px;
  line-height: 140%;
  @include desktop {
    font-size: 20px;
  }
}

@mixin body--small {
  font-size: 14px;
  line-height: 140%;
  @include desktop {
    font-weight: 500;
    font-size: 16px;
  }
}

@mixin body--xsmall {
  font-size: 12px;
  line-height: 130%;
  @include desktop {
    font-weight: 500;
    font-size: 14px;
  }
}

@mixin slug {
  font-size: 12px;
  line-height: 150%;
  letter-spacing: 0.06em;
  text-transform: uppercase;
  font-style: normal;
  @include desktop {
    font-weight: 500;
    font-size: 14px;
    line-height: 110%;
  }
}

@mixin table-value {
  font-size: 12px;
  line-height: 150%;
  @include desktop {
    font-weight: 500;
    font-size: 14px;
    line-height: 110%;
  }
}
@mixin cta--primary {
  font-size: 16px;
  line-height: 140%;
  text-align: center;
}

@mixin cta--small {
  font-weight: 500;
  font-size: 12px;
  line-height: 110%;
  letter-spacing: 0.04em;
}

@mixin cta--text {
  font-weight: bold;
  font-size: 14px;
  line-height: 140%;
  text-decoration-line: underline;
  @include desktop {
    font-size: 16px;
  }
}

@mixin tag {
  font-weight: 500;
  font-size: 14px;
  line-height: 110%;
  @include desktop {
    font-weight: 500;
    font-size: 18px;
  }
}

@mixin meta {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 110%;
  letter-spacing: 0.06em;
  text-transform: uppercase;
}
