.c-pdp-image__figure {
  img {
    width: 100%;
    height: auto;
    max-height: 215px;
    display: block;
    object-fit: contain;
    // border: 1px solid #e7e7e4;
    border-radius: 10px;
  }
}
