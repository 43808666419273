@import "sass/utils/vars";
@import "sass/utils/colors";
.c-featured-products {
  background: $cream;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  padding: 0 $spacer * 3 $spacer * 12;

  @include desktop {
    padding: 0 0 $spacer * 20;
  }
  &__inner {
    width: 100%;
    max-width: 920px;
    margin: 0 auto 0;
    max-height: 0;
    transition: all 0.5s ease-in-out 1s, opacity 0.25s linear 2s;
    opacity: 0;
    overflow: hidden;
    padding-top: 0;
    box-sizing: content-box;
    &--loaded {
      padding-top: $spacer * 12;
      @include desktop {
        padding-top: $spacer * 20;
      }
      opacity: 1;
      max-height: 550px;
      overflow: visible;
      margin: 0 auto $spacer * 12.5;
    }
    &--just-padding {
      padding-top: $spacer * 20;
    }
    &--half-padding {
      padding-top: $spacer * 10;
    }
    .slug {
      margin-bottom: $spacer * 3.5;
    }
    .swiper-container {
      width: 100%;
      height: 480px;
      overflow: visible;
      max-width: 324px;
      margin: 0;

      .swiper-slide {
        max-width: 324px;
      }
    }
    .c-recommended-products {
      position: relative;
      height: 480px;
      border-radius: $border-radius;
      overflow: visible;
      display: flex;
      flex-direction: column;
      &:last-of-type {
        margin-right: 0 !important;
      }
      .c-product-card {
        height: 100%;
        .c-card-gradient {
          clip-path: circle(380px at center);
        }
      }
      @include tablet-portrait-and-up {
        max-width: 356px;
      }
      figure {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-size: cover;
        z-index: 0;
      }
    }
  }
  &__product {
    &__inner {
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      position: relative;
      padding: $spacer * 3;
      z-index: 2;
      color: $white;
      overflow: hidden;
      border-radius: $spacer/2;
      height: 100%;
      box-sizing: border-box;

      &--no-feelings {
        background: $white;
        color: $charcoal;
        .c-circle-arrow-button {
          border: 1px solid $charcoal;
        }
      }
      * {
        position: relative;
        z-index: 1;
      }
      .meta {
        min-height: $spacer * 10;
        div {
          margin-bottom: $spacer;
          &:last-of-type {
            margin-bottom: 0;
          }
        }
      }
      figure {
        z-index: 0;
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
      }
    }
    &__text {
      font-size: 28px;
      line-height: 31px;
      flex: 1;
      span {
        display: block;
      }
      &__vendor {
        opacity: 0.5;
      }
    }
    &__link {
      position: relative;
      border-radius: 100%;
      background: $white;
      display: flex;
      align-items: center;
      justify-content: center;
      height: $spacer * 4.5;
      width: $spacer * 4.5;
      padding: 0;
      svg {
        width: 18px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }
  &__shop-all {
    text-align: center;
    font-size: 28px;
    line-height: 30px;

    span {
      position: relative;
      &:after {
        content: "";
        height: 1px;
        width: 100%;
        border-top: 1px solid $gray20;
        position: absolute;
        bottom: -$spacer;
        left: 0;
      }
    }
    @include desktop {
      font-size: 44px;
      line-height: 74px;
    }
  }
  &__nav {
    display: flex;
    font-size: 12px;
    margin-top: $spacer * 2;
    &__pages {
      flex: 1;
      display: flex;
      &__page {
        display: block;
        margin-right: $spacer;
        font-size: 12px;
        color: $gray90;
        &--active {
          color: $charcoal;
        }
      }
    }
    &__next,
    &__prev {
      padding: 0;
    }
    &__prev {
      margin-right: $spacer;
    }
    &__next {
      opacity: 1;
      font-weight: 500;
      transition: opacity 0.25s linear;
      text-transform: uppercase;
      &--disabled {
        opacity: 0.125;
      }
    }
  }
  //CTA CSS
  &__cta {
    margin-left: auto;
    margin-right: auto;
  }
}
