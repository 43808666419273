$gridGap: 4px;
.grid { 
  display: flex;
  flex-wrap:wrap;
  width:calc(100% + 8px);
  margin:0 -$gridGap/2;

  &__col{
    margin:$gridGap/2;
    &--4{
      width:calc(33.3333% - #{$gridGap})
    }
    &--12{
      grid-column: col-start / span 12;
    }
  }
}