@mixin l-main {
  max-width: 100%;
  width: 100%;
  margin: 0 auto;
  padding: $spacer * 5 $spacer * 4;
  z-index: 2;
  overflow: hidden;
  border-bottom-right-radius: $border-radius-desktop;
  border-bottom-left-radius: $border-radius-desktop;
  flex: 1;
  min-height: calc(100vh - 153px);
  @include desktop {
    padding: $spacer * 5 0;
    border-bottom-right-radius: $border-radius-desktop;
    border-bottom-left-radius: $border-radius-desktop;
  }
  @include below-tablet {
    padding-left: $spacer * 3;
    padding-right: $spacer * 3;
  }
}

.l-main {
  @include l-main;
}

@mixin l-maxwidth {
  max-width: 920px;
  margin: 0 auto;
  &--small {
    max-width: 600px;
  }
  &--medium {
    max-width: 640px;
  }
  &--large {
    max-width: 1100px;
  }
}

.l-maxwidth {
  @include l-maxwidth;
}
