@import 'sass/utils/vars';
@import 'sass/utils/colors';
@import 'sass/utils/typemixins';
.c-inventory-filters-sort{
  &__options{
    display:flex;
    flex-direction:column;
    label{
      margin-bottom:$spacer*2;
      input{
        margin-right:$spacer;
      }
      span{
        @include body;
      }
      &:last-of-type{
        margin-bottom:0;
      }
    }
  }
}