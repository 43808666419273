@import 'sass/utils/colors';
@import 'sass/utils/vars';
.c-pill{
  display:flex;
  justify-content:center;
  align-items:center;
  border:0;
  outline:0;
  background-color:$white;
  margin:0;
  width:100%;
  border-radius:80px;
  height:$spacer*4;
  transition:background-color 0.25s linear;
  padding:0 $spacer*1.5 0 $spacer;
  cursor:pointer;
  svg{
    margin-right:$spacer/2;
  }
  &--active{
    background-color:$deepblue;
    color:$white;
    box-shadow: 0px 5px 7px rgba(0,0,0,0.25);
  }
}