@import 'sass/utils/vars';
@import 'sass/utils/colors';
.c-inventory-filter-sort{
  display:flex;
  margin-top:$spacer;
  .c-pill{
    display:inline-flex;
    width:auto;
    margin-right:$spacer*.75;
  }
  &__filter-count{
    display:flex;
    flex-direction:column;
    align-items:center;
    justify-content:center;
    height:20px;
    width:20px;
    margin-left:2px;
    margin-right:6px;
    border-radius:100%;
    background:$deepblue;
    color:$white;
  }
}