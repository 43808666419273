@import "sass/utils/vars";
@import "sass/utils/colors";
@import "sass/utils/typemixins";
.c-account-home {
  background: $cream;
  opacity: 1;
  transition: opacity 0.25s linear;
  &--logging-out {
    opacity: 0.25;
    pointer-events: none;
  }
  &__dispensary {
    margin: $spacer * 5 0;
    @include desktop {
      margin: $spacer * 7 0 $spacer * 6;
    }
    .c-icon-button--select-dispensary {
      width: calc(100% + #{$spacer * 8});
      max-width: calc(100% + #{$spacer * 8});
      margin-left: -$spacer * 4;
      border-radius: 0;
      height: 64px;
      @include below-tablet {
        padding: 0 $spacer * 5;
      }
      @include tablet {
        height: 72px;
        width: 100%;
        margin-left: 0;
        max-width: 100%;
      }
      .c-icon-button__icon {
        margin-top: -$spacer;
      }
    }
  }
  &__slug {
    color: $gray90;
    margin-bottom: $spacer * 2;
    @include tablet {
      margin-bottom: $spacer * 4;
    }
  }
  &__last-order {
    h3 {
      margin: 0 0 $spacer * 2;
      @include desktop {
        margin: 0 0 $spacer * 2 0;
      }
      @include desktop {
        margin: 0 0 $spacer * 2 $spacer * 3.5;
      }
    }
    .c-button {
      margin-top: $spacer * 2;
    }
    @include desktop {
      width: 100%;
      margin: 0 auto;
    }
  }
  &__no-history {
    display: flex;
    flex-direction: column;
    justify-content: center;
    h3 {
      margin-left: 0;
      margin-bottom: 0;
    }
    .c-button {
      max-width: 100%;
      width: 100%;
    }
  }
  &__profile-account {
    margin-top: $spacer * 9;
    h3 {
      margin-bottom: $spacer * 2;
    }
    @include below-tablet {
      .c-icon-button {
        padding-left: $spacer * 3 !important;
      }
    }
    @include tablet {
      margin-top: $spacer * 6;
    }
    @include desktop {
      display: flex;
      width: 100%;
      margin: $spacer * 9 0;
    }
  }
  &__profile {
    flex: 1;
    width: 100%;
    // width: calc(100% + #{$spacer * 8});
    // margin-left: -$spacer * 4;
    @include tablet {
      min-width: 375px;
      // width: 100%;
      // margin-left: 0;
    }
    .c-icon-button__icon {
      height: 24px;
      width: 24px;
      margin-left: $spacer * 2;
      margin-right: $spacer * 2;
    }
    h3 {
      margin-left: $spacer * 3;
      @include tablet {
        margin-left: 0;
      }
      @include desktop {
        margin-left: $spacer * 3;
      }
    }
    .c-icon-button--profile-link {
      padding: $spacer * 4 $spacer * 2;
      max-width: 100%;
      @include below-tablet {
        border-radius: 0;
      }
    }
  }
  &__account {
    flex: 1;
    padding-left: 0;
    margin-top: $spacer * 3;
    @include tablet {
      margin-top: $spacer * 6;
    }
    @include desktop {
      padding-left: $spacer * 7;
      margin-top: 0;
    }
  }
  &__account-table {
    display: flex;
    flex-direction: column;
  }
  &__account-row {
    display: flex;
    align-items: center;
    margin-bottom: $spacer * 2.5;
    &__label {
      @include slug;
      width: 35%;
      min-width: 120px;
      flex: 0 0 auto;
      text-transform: uppercase;
      color: $gray90;
      @include desktop {
        font-size: 12px;
      }
    }
    &__value {
      display: flex;
      flex-wrap: nowrap;
      align-items: flex-start;
      text-transform: none;
      width: 65%;
      @include body--small;
      span {
        white-space: nowrap;
        width: calc(100% - 16px);
        @include below-tablet {
          word-break: break-word;
          white-space: normal;
        }
      }
      svg {
        margin-right: $spacer/2;
        margin-top: 4px;
        width: 12px;
      }
    }
  }
  &__settings-link,
  &__signout {
    display: block;
    @include cta--text;
    text-decoration: underline;
    padding: 0;
    margin-bottom: $spacer;
  }
}
