@import 'sass/utils/vars';
@import 'sass/utils/colors';
@import 'sass/utils/typemixins';
.c-reservation-details-form{
  margin-top:$spacer*5;
  .form-control{
    margin-bottom:$spacer;
    &:not(.form-control--invalid){
      border:1px solid $darkcream;
    }
    input{
      width:calc(100% - #{$spacer*2});
    }
  }
  .c-button{
    margin-top:$spacer;
    max-width:100% !important;
    width:100%;
    &:first-of-type{
      margin-top:$spacer*3;
    }
  }
  &__forgot{
    @include cta--text;
    text-decoration:underline;
    margin-left:$spacer*2.25;
  }
  &__label{
    text-align: left;
    padding-left: $spacer;
    margin:$spacer*3 0 $spacer;
  }
}