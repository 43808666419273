@import 'sass/utils/vars';
@import 'sass/utils/colors';
.c-account-profile{
  &__name{
    display:flex;
    flex-direction:column;
    align-items:center;
    figure{
      width:150px;
      height:150px;
      position:relative;
      border-radius:100%;
      overflow:hidden;
      mask-image: radial-gradient(white, black);
      .c-card-gradient{
        height:100%;
        width:100%;
        object-fit:cover;
      }
    }
    h1{
      margin-top:$spacer*4;
    }
  }
}