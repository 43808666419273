@import 'sass/utils/vars';
@import 'sass/utils/colors';
@import 'sass/utils/typemixins';
.c-price-selectors{
  display:flex;
  justify-content:space-between;
  &__price{
    background:$white;
    color:$charcoal;
    transition:all 0.25s linear;
    cursor:pointer;
    @include below-tablet{
      max-width:calc(33.33% - #{$spacer*0.75});
      height:142px;
    }
    &--active{
      background:$deepblue;
      .c-price-selectors__price__label{
        color:$white !important;
      }
      .checkmark-icon{
        opacity:1;
      }
    }
    &__label{
      @include h1;
      font-size:40px !important;
      transition: all 0.25s linear;
    }
  }
}