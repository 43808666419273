@import "sass/utils/vars";
@import "sass/utils/colors";
@import "sass/utils/typemixins";
.c-pdp-feelings {
  margin: $spacer * 8 0;
  .slug {
    margin-bottom: $spacer * 2;
  }
  &__grid {
    position: relative;
    display: flex;
    flex-wrap: nowrap;
    border-top: 1px solid $gray20;
    &__feeling {
      max-width: 33.33333%;
      position: relative;
      height: 112px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      flex: 0 0 auto;
      min-width: 33.333%;
      border-bottom: 1px solid $gray20;
      border-right: 1px solid $gray20;
      @include tablet {
        height: 150px;
        // width:50%;
      }
      &:nth-of-type(3) {
        // @include below-desktop{
        border-right: 0;
        // }
      }
      &__icon {
        flex: 0 0 auto;
        height: 60px;
        margin-bottom: $spacer;
        svg {
          object-fit: contain;
          height: 100%;
          width: 100%;
        }
      }

      &__label {
        flex: 0 0 auto;
        @include body--xsmall;
        @include tablet {
          margin-top: $spacer * 1.5;
        }
      }
      // &:nth-child(even) {
      //   @include tablet{
      //     border-right:0;
      //   }
      // }
      &:nth-child(even) &,
      &:nth-child(3) & {
        @include tablet {
          &__label {
            text-align: center;
          }
        }
      }
    }
  }
}
