@import 'sass/utils/vars';
@import 'sass/utils/colors';
@import 'sass/utils/typemixins';
.c-pdp-discounts{
  margin:0 0 $spacer;
  width:100%;
  padding-right:$spacer*4;
  display:flex;
  flex-direction:column;
  @include below-desktop{
    padding-right:0;
    align-items:center;
    margin:(-$spacer) 0 $spacer*2;
  }
  &__discount{
    display:inline-block;
   
    
    &__inner{
      display:inline-block;
      width:auto;
      background:$darkcream;
      padding:$spacer*1.5;
      margin-bottom:$spacer/2;
      border-radius:$spacer/2;
    }

    @include below-desktop{
      display:flex;
      flex-direction:column;
      align-items:center;
      max-width:90%;
      &__inner{
        text-align:center;
        background:$cream;
      }
    }
  }
}