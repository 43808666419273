@import 'sass/utils/vars';
@import 'sass/utils/colors';  
.c-reservation-details{
  background:$cream;
  h1{
    text-align:left;
    max-width:100%;

    @include tablet{
      text-align:center;
      max-width:420px;
      margin:0 auto;
    }
    @include desktop{
      max-width:500px;
    }
  }
  p:not(.c-reservation-details-form__label){
    text-align:left;
    margin:$spacer*1.5 0;
    @include tablet{
      text-align:center;
      max-width:350px;
      margin:$spacer*3 auto;
    }
    @include desktop{
      max-width:450px;
    }
  }
  .c-button{
    max-width:100%;
    @include desktop{
      max-width:314px;
    }
  }
  &__form{
    max-width:400px;
    margin:0 auto;
  }
}