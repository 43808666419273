@import 'sass/utils/vars';
@import 'sass/utils/colors';
@import 'sass/utils/typemixins';
.c-accordion{
  border-top:1px solid $gray30;
  overflow:hidden;
  &__toggle{
    display:flex;
    justify-content:space-between;
    align-items:center;
    padding:0 $spacer*1.5 0 0 ;
    width:100%;
    max-height:74px;
    height:100px;
    padding-bottom:0;
    transition:max-height 0.5s ease-in-out 0s, padding-bottom 0.5s ease-in-out;
    &--hasSelected{
      max-height:85px;
      padding-bottom:$spacer*2.5;
      transition:max-height 0.5s ease-in-out 0.5s, padding-bottom 0.5s ease-in-out 0.5s;
    }
    &__inner{
      position:relative;
      display:flex;
      justify-content:space-between;
      align-items:center;
      width:100%;
    }
    cursor:pointer;
    h3{
      flex:1;
    }
    svg{
      flex: 0 0 auto;
      transform:rotate(0deg);
      transition:transform 0.25s linear;
    }
  }
  &__content{
    max-height:0;
    opacity:0;
    padding-bottom:0;
    transition:all 0.5s ease-in-out, opacity 0.25s linear;
    .form-control--checkbox{
      &:last-of-type{
        margin-bottom:0;
      }
    }
  }
  &__selected{
    position:absolute;
    top:calc(100% + #{$spacer});
    left:0;
    opacity:0;
    transition:opacity 0.25s linear;
    @include body--xsmall;
    color:$gray80;

    &--display{
      opacity:1;
      transition:opacity 0.25s linear 0.5s;
    }
  }
  &--open{
    .c-accordion__toggle{
      svg{
        transform:rotate(-180deg);
      }
    }
    .c-accordion__content{
      max-height:1700px;
      opacity:1;
      padding-bottom:$spacer*3.5;
      transition:all 0.5s ease-in-out, opacity 0.25s linear 0.25s;
    }
  }
}