@import 'sass/utils/vars';
@import 'sass/utils/colors';
@import 'sass/utils/typemixins';
.c-pdp-pairs{
  margin:$spacer*10 0 $spacer*9;
  .slug{
    margin-bottom:$spacer*2;
  }
  &__category{
    display:flex;
    align-items:center;
    background:$gray10;
    border-radius:$border-radius*1.25;
    height:$spacer*8;
    padding:0 $spacer 0 $spacer*2.25;
    width:100%;
    margin-bottom:$spacer;
    &__icon{
      height:$spacer*4;
      width:$spacer*4;
      margin-right:$spacer*1.5;
      display:flex;
      justify-content:center;
      align-items:center;
      svg{
        height:100%; 
        width:100%;
        object-fit:contain;
      }
    }
    span{
      display:block;
      flex:1;
    }
  }
  &__arrow-circle{
    display:flex;
    justify-content:center;
    align-items:center;
    height:$spacer*4.5;
    width:$spacer*4.5;
    background:$white;
    border-radius:100%;
  }
}