@import 'sass/utils/vars';
@import 'sass/utils/colors';
.c-dispensary-near{
  background:$cream;
  padding: 0 $spacer*3 $spacer*10;
  &__inner{
    display:flex;
    flex-direction:column;
    position:relative;
    height:324px;
    width:100%;
    max-width:920px;
    margin:0 auto;
    overflow:hidden;
    border-radius:$border-radius;
  }
  
  &__figure{
    background-size:cover;
    background-position:center center;
    position:absolute;
    top:0;
    bottom:0;
    right:0;
    left:0;
    z-index:0;
    background:$darkcream;
    img{
      position:absolute;
      width:100%;
      height:100%;
      object-fit:cover;
      transition:opacity 0.25s linear;
    }
  }
  &__content{
    position:relative;
    flex:1;
    z-index:1;
    display:flex;
    flex-direction:column;
    padding:$spacer*3;
    color:$white;
    h2{
      flex:1;
      margin-top:$spacer;
      max-width:80%;
    }
  }
}