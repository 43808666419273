@import 'sass/utils/vars';
@import 'sass/utils/colors';
@import 'sass/utils/typemixins';
.c-pdp-details{
  display:flex;
  flex-wrap:wrap;
  margin:$spacer*5 0;
  padding: 0 $spacer*2;
  @include desktop{
    margin:$spacer*10 0;
  }
  &__item{
    width:50%;
    flex: 0 0 auto;
    margin-bottom:$spacer*1.5;
    .slug{
      margin-bottom:$spacer*.15;
      color:$gray90;
    }
    span{
      @include body--small;
      font-weight:500;
    }
  }
}
