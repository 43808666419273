@import 'sass/utils/colors';
@import 'sass/utils/vars';
.c-dispensaries{
  .slug{
    margin-bottom:$spacer*2;
    padding-top:0;
    display:flex;
    justify-content:space-between;
    align-items:center;
    @include desktop{
      margin-top:$spacer*5;
    }
  }
  &__group-label{
    margin: $spacer*5 0 $spacer*2;
    @include desktop{
      margin: 0;
    }
    &--search{
      display:none;
      @include desktop{
        display:block;
      }
    }
  }

  &__group{
    &__list{
      .c-button{
        margin-top:$spacer*5;
      }
    }
   
    @include desktop{
      border-top:1px solid $gray30;
      &__inner{
        width:100%;
        display:flex;
        justify-content: space-between;
        padding:$spacer*3 0;
      }
      &__list{
        max-width:680px;
        width:100%;
      }
    }
    &--active{
      margin-bottom:$spacer*5;
      @include desktop{
        border-top:0;
        border-bottom:1px solid $gray30;
        .slug{
          margin-top:0;
          // align-items:flex-start;
        }
      }
    }
  }
 
  &__no-locations{
    opacity:0;
    max-height:0;
    overflow:hidden;
    &.appear-active,
    &.appear-done,
    &.enter-active,
    &.enter-done,
    &.exit{
      opacity:1;
      max-height:30px;
    }
    &.exit-active,
    &.exit-done{
      max-height:0;
      opacity:0;
    }
    &.appear-active,
    &.enter-active{
      transition:max-height 0.25s linear 0.375s, opacity 0.25s linear .75s;
    }

    &.exit-active{
      transition:max-height 0.25s linear 0.25s, opacity 0.25s linear 0s;
    }
  }
}