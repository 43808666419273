/**
 * ==============================================
 * Dot Pulse
 * ==============================================
 */

$pulse-color: #000;

@keyframes dotPulseBefore {
  0% {
    box-shadow: 9984px 0 0 -5px $pulse-color;
  }
  30% {
    box-shadow: 9984px 0 0 2px $pulse-color;
  }
  60%,
  100% {
    box-shadow: 9984px 0 0 -5px $pulse-color;
  }
}
@keyframes dotPulse {
  0% {
    box-shadow: 9999px 0 0 -5px $pulse-color;
  }
  30% {
    box-shadow: 9999px 0 0 2px $pulse-color;
  }
  60%,
  100% {
    box-shadow: 9999px 0 0 -5px $pulse-color;
  }
}
@keyframes dotPulseAfter {
  0% {
    box-shadow: 10014px 0 0 -5px $pulse-color;
  }
  30% {
    box-shadow: 10014px 0 0 2px $pulse-color;
  }
  60%,
  100% {
    box-shadow: 10014px 0 0 -5px $pulse-color;
  }
}
.dot-pulse-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 2rem 0;
  margin: 0 -5%;
  overflow: hidden;
}

.dot-pulse {
  position: relative;
  left: -9999px;
  width: 8px;
  height: 8px;
  border-radius: 5px;
  background-color: $pulse-color;
  color: $pulse-color;
  box-shadow: 9999px 0 0 -5px $pulse-color;
  animation: dotPulse 1.5s infinite linear;
  animation-delay: 0.25s;
  &::before {
    content: "";
    display: inline-block;
    position: absolute;
    top: 0;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: $pulse-color;
    color: $pulse-color;
    box-shadow: 9984px 0 0 -5px $pulse-color;
    animation: dotPulseBefore 1.5s infinite linear;
    animation-delay: 0s;
  }
  &::after {
    content: "";
    display: inline-block;
    position: absolute;
    top: 0;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: $pulse-color;
    color: $pulse-color;
    box-shadow: 10014px 0 0 -5px $pulse-color;
    animation: dotPulseAfter 1.5s infinite linear;
    animation-delay: 0.5s;
  }
}
