@import 'sass/utils/vars';
@import 'sass/utils/colors';
.c-inventory-filters-sort--filter{
  &--fullHeight{
    .c-inventory-filters-sort__content{
      border-radius:0;
    }
  }
  .c-inventory-filters-sort__content{
    display:flex;
    padding-bottom:$spacer*8.5;
    transition:all 0.5s ease-in-out 0s, border-radius 0.25s linear;
    @include desktop{
      max-height:100% !important;
    }
  }
  .c-inventory-filters-sort__header{
    margin-bottom:$spacer*2.5;
  }
}
.c-filter-form{
  max-height:100%;
  padding-top:$spacer*2.5;
  border-top:1px solid transparent;
  overflow:scroll;
  transition:border-top-color 0.25s linear;
  &--scrolled{
    border-top-color:$gray30;
  }
  &__submit-wrapper{
    position:fixed;
    bottom:0;
    padding-bottom:$spacer*2;
    background:#fff;
    left:$spacer*2.5;
    right:$spacer*2.5;
    @include desktop{
      right:auto;
      width:441px;
      left: 72px;
      .c-button{
        width:100%;
        max-width:100%;
      }
    }
  }
}
.c-filter-form::-webkit-scrollbar {
  display: none;
}

::-webkit-scrollbar {
  display: none;
}