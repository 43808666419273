@import 'sass/utils/vars';
@import 'sass/utils/colors';
@import 'sass/utils/typemixins';
@mixin white-header{
  .c-header{
    .i-hamburger{
      path{
        stroke: $white !important;
        transition:stroke 0.25s linear 0.125s;
      }
    }
    &__logo-link{
      path{
        fill:$white !important;
        transition:fill 0.25s linear 0.125s;
      }
    }
    &__controls{
      &__account{
        svg{
          circle,path{
            stroke:$white !important;
            transition:stroke 0.25s linear 0.125s;
          }
        }
      }
      &__cart{
        border-color:$white;
        color:$white;
        transition:all 0.25s linear 0.125s;
      }
    }
  
    &__location{
      path{
        stroke: $white !important;
      }
      &__button{
        border:1px solid rgba(255,255,255,0.2);
        span{
          color:$white;
        }
      }
    }
    &__links{
      color:$white;
      a{
        color:$white;
      }
    }
  }
}

.c-header{
  position:absolute;
  top:0;
  left:0;
  right:0;
  height:$spacer*8.5;
  display:flex;
  z-index:13;
  transform:translateY(0);
  transition:transform 0s ease-in-out, box-shadow 0.5s ease-in-out, background-color 0.25s linear;
  box-shadow:none;
  overflow-x: hidden;
  &--hidden{
    transform:translateY(-100%);
  }
  &--ghosted{
    position:fixed;
    top:0;
    left:0;
    right:0;
    transition:transform 0.5s ease-in-out, box-shadow 0.5s ease-in-out, background-color 0.25s linear;
    background:rgba(246, 242, 238, 0.9) !important;
    box-shadow:0px 0px 10px rgba(0,0,0,0.125);
    .c-page-wrapper--dispensary-slug  &,
    .c-page-wrapper--dispensary--slug-product--id &,
    .c-page-wrapper--home &{
      @include below-tablet{
        background:rgba(255,255,255, 0.9) !important;
      }
    }
    .c-page-wrapper--home &{
      background:rgba(255,255,255, 0.9) !important;
      .c-header:not(.c-header--mood-selector-open){
        background:rgba(19,19,17, 0.9) !important;
      }
      @include below-tablet{
      }
    }
  }
  &--shadow{
    box-shadow:0px 0px 10px rgba(0,0,0,0.125);
  }
  .i-hamburger{
    opacity:1;
    transition:opacity 0.25s linear 0.25s;
  }
  .i-close-icon{
    position:absolute;
    left:50%;
    top:50%;
    transform:translate(-50%, -50%);
    opacity:0;
    transition:opacity 0.25s linear 0s;
    path{
      stroke:$white !important;
    }
  }
  &--nav-open{
    background:$deepblue;
    position:fixed;
    
    @media (min-height: 760px) {
      background:transparent!important;
    }
    
    .i-hamburger{
      opacity:0;
      transition:opacity 0.25s linear 0s;
    }
    .i-close-icon{
      opacity:1;
      transition:opacity 0.25s linear 0.25s;
    }
  }
  
  // @include below-tablet{
  //   position:relative;
  //   top:auto;
  // }
  &--drawer-open{
    // z-index:1;
    transform:translateY(-100%);
  }
  
  &__inner{
    position:relative;
    height:$spacer*8.5;
    display:flex;
    max-width:920px;
    margin:0 auto;
    justify-content:space-between;
    height:100%;
    align-items:center;
    width:calc(100% - #{$spacer*6}) ;
    &__nav{
      flex: 1;
      display: flex;
      align-items:center;
      transition:opacity 0.25s linear 0.5s, z-index 0s linear 0s;
    }
    &__logo-hamburger{
      display:flex;
      align-items:center;
      .logo{
        height:18px;
        @include tablet{
          height:24px;
        }
      }
    }
  }
  &__logo-link{
    margin-top:$spacer*1.5;
    margin-left:$spacer*2;
    @include desktop{
      margin-left:0;
    }
    path{
      transition:fill 0.25s linear 0.25s;
    }
  }
  &__back{
    display:flex;
    align-items:center;
    padding:0;
    @include below-tablet{
      margin-left:-$spacer;
    }
    svg{
      margin-top:$spacer/4;
      height:24px;
      margin-right:$spacer/4;
    }
  }
  &__controls{
    position:relative;
    display:flex;
    align-items:center;
    height:100%;
    z-index:1;
    &__account{
      padding:$spacer;
      margin-top:$spacer;
      svg{
        height:$spacer*2;
        circle,path{
          transition:stroke 0.25s linear 0.25s;
        }
      }
    }
    &__cart{
      margin-left:$spacer;
      height:$spacer*3.5;
      width:$spacer*3.5;
      display:flex;
      align-items:center;
      justify-content:center;
      border:1.5px solid $charcoal;
      border-radius:100%;
      font-size:12px; 
      transition:all 0.25s linear 0.25s;
      opacity:1;
      &--active{
        border-color:$deepblue;
        border-width:2px;
        color:$deepblue;
      }
    }
    &--no-preorders{
      .c-header__controls__cart{
        margin-right:-$spacer*4.5;
        opacity:0;
        transition: opacity 0.5s linear 0s, margin-left 0.25s ease-in-out 0.25s;
        pointer-events:none;
      }
    }
  }
  &__location{
    margin-left:$spacer*3;
    &__button{
      display:flex;
      align-items:center;
      height:41px;
      width:224px;
      border-radius:$border-radius;
      border:1px solid $gray20;
      font-size:14px;
      padding:0 $spacer*2 0 $spacer*.75;
      span{
        flex:1;
        display:block;
        margin-left:$spacer;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        color:$charcoal;
      }
    }
  }
  &__links{
    list-style:none;
    display:flex;
    color:$charcoal;
    flex:1;
    a{
      color:$charcoal;
      font-size:14px;
      margin-right:$spacer*2;
    }
  }
  &__hamburger{
    position:relative;
    padding:0;
    path{
      stroke:$charcoal;
      transition:stroke 0.25s linear 0.25s;
    }
    @include desktop{
      display: none;
    }
  }
  &__mood-selector{
    position:absolute;
    top:0;
    left:0;
    height:$spacer*8.5;
    right:0;
    display:flex;
    align-items:center;
    opacity:0;
    z-index:0;
    &__progress__label{
      position:absolute;
      top:calc(50% + #{$spacer*1.5});
      left:50%;
      transform:translateX(-50%);
      @include body--xsmall;
    }
    &__progress{
      position:absolute;
      left:50%;
      top:50%;
      width:280px;
      max-width:180px;
      height:$spacer/2;
      border-radius:$spacer/4;
      background-color:rgba(16,16,16,0.1);
      transform:translate(-50%, -50%);
      overflow:hidden;
      @include tablet{
        height:$spacer;
        border-radius:$spacer/2;
        max-width:45vw;
      }
      &__indicator{
        background-color:$deepblue;
        width:100%;
        height:$spacer;
        transition:max-width 0.5s ease-in-out;
      }
    }
    &--appear-active,
    &--appear-done,
    &--enter-active,
    &--enter-done,
    &--exit{
      opacity:1;
      z-index:1;
    }
    &--appear-active,
    &--enter-active{
      transition:opacity 0.5s linear 1s, z-index 0s linear 0s;
    }

    &--exit-active,
    &--exit-done{
      opacity:0;
      z-index:-1;
    }
    &--exit-active{
      transition:opacity 0.25s linear 0s, z-index 0s linear 0.25s;
    }
  }

  .c-page-wrapper--menu-open & {
    @include white-header;
  }
  .c-page-wrapper--home:not(.c-page-wrapper--kiosk) &,
  .c-page-wrapper--recommendations:not(.c-page-wrapper--kiosk) &{
    &:not(.c-header--mood-selector-open):not(.c-header--ghosted){
      @include desktop{
        @include white-header;
      }
    }
  }
}

