@import "sass/utils/colors";
@import "sass/utils/vars";
@import "sass/utils/typemixins";
.c-atc {
  display: flex;
  background: $charcoal;
  color: $white;
  border-radius: $border-radius/2;
  height: $spacer * 7;
  z-index: 5;
  // overflow:hidden;
  position: relative;
  &--out-of-stock {
    opacity: 0.5;
    pointer-events: none;
  }
  &__quantity {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 0 0 auto;
    width: $spacer * 8;
    border-right: 1px solid rgba(255, 255, 255, 0.2);
    color: $white;
    @include cta--primary;

    span {
      display: inline-block;
      padding: 0 $spacer;
    }

    &-icon {
      transform-origin: center 55%;
      transition: transform 0.1s;

      &--rotated {
        transform: rotate(180deg);
      }
    }
  }

  &__add {
    display: flex;
    align-items: center;
    color: $white;
    padding-left: $spacer * 3;
    flex: 1;
    cursor: default;
    &--pointer {
      cursor: pointer;
    }
    @include cta--primary;
  }

  &__quantity-selector {
    position: absolute;
    width: $spacer * 8;
    top: calc(100% - 6px);
    left: 0;
    max-height: 0;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    transition: max-height 0.25s ease-out;
    border-radius: 0 0 6px 6px;
    &__option {
      height: $spacer * 5;
      background: $charcoal;
      color: $white;
      flex: 0 0 auto;
      @include cta--primary;
      border-top: 1px solid rgba(255, 255, 255, 0.2);
      &--active {
        font-weight: 700;
      }
      &:first-of-type {
        display: none;
      }
    }
    &--show-zero {
      .c-atc__quantity-selector__option {
        &:first-of-type {
          display: block;
        }
      }
    }
    &--open {
      max-height: $spacer * 22;
      overflow: scroll;
      min-height: 115px !important;
    }
  }
  .c-button--preorder-disabled {
    svg {
      display: none;
    }
  }
}
