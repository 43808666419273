$tablet-width: 768px;
$landscape-tablet-width: 900px;
$desktop-width: 1024px;
$border-radius: 8px;
$border-radius-desktop: 20px;
$spacer: 8px;

@mixin mobile-landscape{
  @media(max-width:991px) and (orientation: landscape){
    @content;
  }
}

@mixin tablet-landscape{
  @media (min-width: #{$desktop-width}) and (max-width: #{$desktop-width}) {
    @content;
  }
}

@mixin tablet-only {
  @media (min-width: #{$tablet-width}) and (max-width: #{$desktop-width - 1px}) and (orientation: landscape) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: #{$desktop-width}) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: #{$tablet-width}) and (orientation: portrait) {
    @content;
  }
  @media (min-width: #{$landscape-tablet-width}) and (orientation: landscape) {
    @content;
  }
}


@mixin tablet-all-orientation {
  @media (min-width: #{$tablet-width - 1}) and (orientation: portrait) {
    @content;
  }
}

@mixin tablet-portrait-and-up{
  @media (min-width: #{$tablet-width}) and (orientation: portrait) {
    @content;
  }
  @media (min-width: #{$desktop-width}) {
    @content;
  }
}

@mixin below-desktop {
  @media (max-width: #{$desktop-width - 1}) {
    @content;
  }
}

@mixin below-tablet {
  @media (max-width: #{$tablet-width - 1}) {
    @content;
  }
  @media (orientation: landscape) and (max-width: #{$desktop-width - 1}) {
    @content;
  }
}

@mixin below-tablet-portrait {
  @media (max-width: #{$tablet-width - 1}) {
    @content;
  }
}

