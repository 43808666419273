@import "sass/utils/colors";
@import "sass/utils/vars";
@import "sass/utils/typemixins";
$topper-border-radius: 20px;
.c-pdp-topper {
  height: 100%;

  @media (max-width: #{$tablet-width - 1}) {
    min-height: 400px;
  }

  @include below-desktop {
    height: auto;

    .c-recommended-products__item--related-products & {
      height: 100%;
      min-height: 642px;
    }
  }

  @include desktop {
    height: 100%;
  }

  .c-recs-discounts {
    color: $white;
    font-style: italic;
    margin: 15px 0;
    font-size: 14px;
  }

  &__drawer {
    position: relative;
    z-index: 0;
    background: $deepblue;
    border-radius: 0 0 $topper-border-radius $topper-border-radius;
    padding: ($spacer * 1.5 + $topper-border-radius) $spacer * 1.5 $spacer * 1.5
      $spacer * 1.5;
    text-align: center;
    @include tablet {
      display: none;
    }
    @include desktop {
      display: block;
      margin-top: -$topper-border-radius;
      padding-left: $spacer * 10;
      text-align: left;
      span {
        display: block;
      }
    }
    @include below-desktop {
      margin-top: -$topper-border-radius;
    }
    @include tablet-only {
      &--tablet-only {
        display: block;
        padding: $spacer * 1.5 $spacer * 1.5
          ($spacer * 1.5 + $topper-border-radius) $spacer * 1.5;
        border-radius: $topper-border-radius $topper-border-radius 0 0;
        max-width: 400px;
        margin: 0 auto (-$spacer * 2.75);
      }
    }
    span {
      @include body--xsmall;
      color: $white;
    }
  }
  &__content {
    position: relative;
    border-radius: $topper-border-radius;
    width: 100%;
    height: 100%;
    z-index: 1;
    padding: $spacer * 9 $spacer * 3.5 $spacer * 8;
    @include below-desktop {
      padding: $spacer * 3 $spacer * 5 $spacer * 12;
      margin-bottom: 0;
      height: 100%;
      &--no-gradient {
        padding: $spacer * 4 0 $spacer * 4;
        max-width: 340px;
        margin: 0 auto;
        .c-pdp-topper__content__details {
          padding: $spacer * 3 0 0;
        }
      }
    }
    @include desktop {
      padding: $spacer * 4 $spacer * 8 $spacer * 5;
    }

    .c-recommended-products__container &__inner {
      display: flex;
      flex-flow: column nowrap;
      height: 100%;

      @include desktop {
        min-height: 452px;
      }
    }

    .c-recommended-products__container & {
      @include desktop {
        padding: $spacer * 6 $spacer * 5 $spacer * 6;
      }
    }
    .c-gradienter {
      opacity: 1;
      z-index: -1;
      svg {
        min-width: 100%;
        min-height: 100%;
        path,
        rect,
        circle {
          transition: transform 3s linear;
        }
      }
    }
    .c-gradienter svg {
      path,
      rect,
      circle {
        transition: transform 10s linear;
      }
    }
    &--animate {
      .c-gradienter svg {
        path,
        rect,
        circle {
          transform: scale(1) rotate(0deg) !important;
        }
      }
    }
    &__details {
      position: relative;
      z-index: 1;
      flex: 1;
      padding: $spacer * 5 0 0;
      @include desktop {
        width: 100%;
        padding-top: $spacer * 2;
        padding-right: 548px;
        padding-bottom: 0;
      }
      // @include tablet-only{
      //   padding-right:432px;
      // }
      &__name {
        will-change: color;
        color: $white;
        @include h1;
        font-size: 28px;
        line-height: 90%;
        @include tablet {
          margin-bottom: $spacer * 1.5;
        }
      }
      &__brand {
        color: $white;
        opacity: 0.4;
      }
      &__type {
        will-change: color, opacity;
        opacity: 0;
        color: $white;
      }

      .c-recommended-products__container & {
        padding: $spacer * 5 0 0;
        display: flex;
        flex-flow: column;
        justify-content: space-between;

        @include desktop {
          width: 48%;
          max-width: 360px;
        }
      }
    }
    &--no-feelings {
      background: $white;
      @include below-tablet {
        border: 1px solid $gray90;
      }

      .c-recommended-products__container & {
        border: none;
      }

      .c-pdp-topper {
        &__icon {
          .i-product-type *[class*="--st0"] {
            stroke: $charcoal;
          }
        }
        &__content {
          &__details {
            h3 {
              color: $charcoal;
            }
          }
        }
        &__pricing {
          color: $charcoal;
        }
      }
    }
  }
  &__flags {
    margin: $spacer * 2 0;
    @include meta;
    color: $white;
    &__flag {
      display: inline-block;
      margin-right: $spacer * 2;
    }
  }
  &__pricing {
    display: inline-block;
    @include price;
    color: $white;
    &__price {
      margin-right: $spacer;
      &--strike {
        text-decoration: line-through;
      }
    }
  }
  &__icon {
    position: relative;
    z-index: 2;
    > div {
      height: 100%;
    }
    .i-product-type {
      width: 100%;
      height: 100%;
      max-height: 120px;
      max-width: 120px;
      *[class*="--st0"] {
        stroke: $white;
      }
    }
    @include tablet {
      height: 120px;
      width: 120px;
      .product-type-icon {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }
  &__image-gradient {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }
  &__atc {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    max-width: calc(100% - 568px);
    padding-top: $spacer;
    padding-left: $spacer * 8;

    .c-recommended-products__container & {
      display: none;
    }

    .c-atc {
      width: 250px;
      @include desktop {
        width: 314px;
      }
    }
    @include tablet-only {
      max-width: calc(100% - 432px);
    }
  }

  .c-recommended-products__container & {
    width: 100%;

    .c-pdp-topper {
      &__drawer {
        @include below-desktop {
          display: none;
        }
      }
      .c-pdp-topper__atc {
        display: none;
      }
      &__row {
        display: flex;
        height: $spacer * 7;
        border-bottom: 1px solid rgba(255, 255, 255, 0.2);
        position: relative;
        z-index: 5;
        &__icon {
          display: flex;
          align-items: center;
          justify-content: center;
          width: $spacer * 8.5;
          border-right: 1px solid rgba(255, 255, 255, 0.2);
          .c-pdp-topper__icon {
            max-height: calc(100% - #{$spacer * 2});
            max-width: calc(100% - #{$spacer * 4});
            width: 100%;
            height: 100%;
            div {
              position: absolute;
              top: 0;
              left: 0;
              right: 0;
              bottom: 0;
            }
          }
          svg {
            position: absolute;
            object-fit: contain;
            height: 100%;
            width: 100%;
            min-height: 0;
            min-width: 0;
          }
        }
        &__price {
          flex: 1;
          display: flex;
          align-items: center;
          padding-left: $spacer * 2;
          color: $white;
        }
      }

      &__content {
        min-height: 200px;
        width: 100%;
        box-sizing: border-box;

        @include below-desktop {
          padding: 0;
          border-radius: $border-radius;
          height: auto;
        }
        @include desktop {
          min-height: 548px;
          box-sizing: border-box;
        }
        &__details {
          &__brand {
            h3 {
              @include desktop {
                font-size: 32px;
              }
            }
          }
          @include below-desktop {
            padding: $spacer * 3;
          }
          @include below-tablet {
            padding: $spacer * 2 $spacer * 3;
            &__name {
              @include h3;
            }
          }
        }
      }

      &__atc {
        padding-top: $spacer * 3;
        @include below-desktop {
          display: none;
        }
      }
    }
    .c-pdp-pickup {
      display: none;
    }
  }

  .c-card-gradient-wrapper {
    position: absolute;
    z-index: 0;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    overflow: hidden;
    border-radius: $border-radius-desktop;

    .c-card-gradient {
      border-radius: $border-radius-desktop;
    }

    @include below-desktop {
      min-height: 400px;
    }

    @include desktop {
      min-height: auto;
    }

    .c-recommended-products__container & {
      border-radius: $border-radius $border-radius 0 0;

      .c-card-gradient {
        border-radius: $border-radius $border-radius 0 0;
      }

      @include desktop {
        border-radius: $border-radius-desktop;

        .c-card-gradient {
          border-radius: $border-radius-desktop;
        }
      }
    }

    &--no-feelings {
      background: $white;
    }
  }
}
