@import 'sass/utils/vars';
@import 'sass/utils/colors';
@import 'sass/utils/typemixins';
.c-details-table{
  &__row{
    display:flex;
    padding: $spacer*1.5 0;
    border-bottom:1px solid $gray20;
    align-items:center;
    &:last-of-type{
      border-bottom:0;
    }
    &__label{
      width:$spacer*20;
      padding-right:$spacer*2.5;
      @include slug;
      color:$gray90;
    }
    &__value{
      @include body--small;
      color:$charcoal;
    }
  }
}