@import 'sass/utils/vars';

.c-order-items{
  h3{
    @include below-tablet{
      margin-top:$spacer*5;
    }
    margin-bottom:$spacer*3;
  }
  .c-button--get-recommendations{
    margin:$spacer*5 0 $spacer*7;
  }
  &__products{
    .c-menu-drawer__children{
      .c-button{
        margin-right:$spacer*2;
      }
    }
  }
}

