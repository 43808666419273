@import 'sass/utils/vars';
@import 'sass/utils/colors';
@import 'sass/utils/typemixins';
.c-pdp-pickup{
  @include body--xsmall;
  margin:$spacer*2.5 0 $spacer*3;

  &--out-of-stock{
    margin:$spacer*2.5 0;
    @include desktop{
      margin-bottom:0;
    }
  }
  &__availability{
    text-align:center;
  }
  &__location{
    display:flex;
    justify-content:center;
    align-items:center;
    span{
      text-decoration:underline;
    }
  }
  .c-pdp-topper & {
    position:relative;
    color:$charcoal;
    margin:0;
    font-size:12px;
    width:100%;
    text-align:center;
    display:flex;
    @include body--xsmall;
    align-items:center;
    justify-content:flex-start;
    margin-bottom:$spacer;
    &__location{
      justify-content:flex-start;
      text-align:left;
    }
    &__availability{
      text-align:left;
    }

    a{
      color:$charcoal;
    }
    svg{
      margin-left:0;
      path, circle{
        // stroke:$white !important;
      }
    }
  }
}