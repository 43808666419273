@import "sass/utils/vars";
@import "sass/utils/colors";
.c-activity-selector {
  width: 100%;
  background: $cream;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  // padding:$spacer*15 0;
  padding: $spacer * 7.5 0;
  position: relative;
  z-index: 1;
  .slug {
    margin-bottom: $spacer * 2.5;
    color: $gray90;
  }
  &__list {
    list-style: none;
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    &__item {
      font-size: 28px;
      line-height: 42px;
      margin-bottom: $spacer;
      font-weight: 400;
      button {
        padding: 0;
        @include below-tablet {
          font-size: 28px;
          line-height: 42px;
          font-weight: 400;
        }
      }
      &:after {
        content: "";
        display: block;
        height: 1px;
        widows: 100%;
        background: $gray30;
      }
    }
  }
  @include tablet-portrait-and-up {
    flex-direction: row;
    padding: 0;
    max-width: 1000px;
    margin: 0 auto;
    height: 62px;
    z-index: 2;
    margin-top: -62px;
    border-radius: $border-radius $border-radius 0 0;
    transition: margin-top 0.5s linear;
    &--selector-open {
      margin-top: 0;
    }
    .slug {
      margin-bottom: 0;
      margin-right: $spacer * 1.5;
    }
    &__list {
      flex-direction: row;
      &__item {
        margin: 0 $spacer * 1.5;
        font-size: 14px;
        line-height: 18px;
        font-weight: 500;
        &:after {
          display: none;
        }
      }
    }
  }
}
