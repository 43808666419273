@import 'sass/utils/vars';
@import 'sass/utils/colors';
@import 'sass/utils/typemixins';
.c-menu-drawer{
  position:fixed;
  top:0;
  bottom:0;
  left:0;
  right:0;
  z-index:14;
  
  .h2 {
    width: 100%;
    text-align: center;
  }
  
  &__backdrop{
    position:fixed;
    top:0;
    bottom:0;
    right:0;
    left:0;
    background-color:$charcoal;
    opacity:0;
  }
  &__sticky-actions{
    position:absolute;
    bottom:0;
    left:0;
    right:0;
    display:flex;
    justify-content:flex-start;
    background:$white;
    padding:$spacer*2 $spacer*3.5;
    box-shadow:none;
    transition:box-shadow 0.25s ease-in-out;
    &--behind-sticky{
      box-shadow:0px 0px 10px rgba(0,0,0,0.25);
      .c-menu-drawer__sticky-actions{
      }
    }
    @include tablet{
      padding:$spacer*2 $spacer*9;
    }
  }
  &__content{
    position:fixed;
    bottom:0;
    left:0;
    right:0;
    transform:translateY(100%);
    border-radius:$border-radius-desktop $border-radius-desktop 0 0;
    overflow:hidden;
    background:#fff;
    padding:$spacer*5 $spacer*3.5;
    .c-button{
      margin-bottom:$spacer;
    }
    @include below-tablet{
      // overflow-y:auto;.
    }

    h1{
      margin-bottom:0 !important;
    }
    &__inner{
      width:100%;
      scrollbar-width: none;
      &--sticky{
        padding-bottom:$spacer*10.5;
        @include tablet{
          padding-bottom:0;
        }
      }
    }
  }
  &__children{
    // flex:1;
    // height:100%;
    //overflow: auto;
  }
  &--sticky-action{
    .c-menu-drawer__content__inner{
      max-height:calc(100vh - #{$spacer*14.5});
      overflow-y: auto;
    }
  }
  &.adjust-recommendations{
    .c-menu-drawer{
      &__content{
        padding-bottom:0 !important;
        display:flex;
        flex-direction:column;
        flex:1;
      }
    }
    .c-button{
      // margin-top:$spacer*2;
      margin-bottom:0;
    }
  }
  &.c-menu-drawer--toast{
    .c-menu-drawer{
      &__children{
        display:flex;
        flex-direction:column;
        @include tablet{
          flex-direction:row;
          justify-content: center;
        }
      }
    }
  }
  @include tablet{
    &.c-menu-drawer--toast{
      .c-menu-drawer{
        &__header{
          padding-bottom:$spacer*3;
        }
        &__content{
          max-width:648px;
          left:50%;
          margin-left:-324px;
          padding:$spacer*3.5;
          &__inner{
            max-width:100%;
          }
        }
        &__children{
          display:flex;
          flex-direction:row;
          .c-button{
            &:first-of-type{
              margin-right:$spacer*2;
            }
          }
        }
      }
    }
    &:not(.c-menu-drawer--toast){
      .c-menu-drawer__content{
        left:auto;
        width:auto;
        max-width:100%;
        transform:translateX(100%);
        height:100vh;
        padding-right:72px;
        padding-left:72px;
        border-radius:$border-radius-desktop 0 0 0;
      }
      &.c-menu-drawer--appear{
        .c-menu-drawer{
          &__content{
            transform:translateX(100%);
          }
        }
      }
      &.c-menu-drawer--appear-active,
      &.c-menu-drawer--appear-done,
      &.c-menu-drawer--enter-active,
      &.c-menu-drawer--enter-done,
      &.c-menu-drawer--exit{
        .c-menu-drawer{
          &__content{
            transform:translateX(0);
          }
        }
      }
      &.c-menu-drawer--exit-active,
      &.c-menu-drawer--exit-done{
        .c-menu-drawer{
          &__content{
            transform:translateX(100%);
            box-shadow:none;
          }
        }
      }
    }
  }

  &__header{
    display:flex;
    justify-content:space-between;
    align-items:center;
    padding-bottom:$spacer*5;
  }
  &__close{
    height:$spacer*4;
    width:$spacer*4;
    border-radius:100%;
    background:$cream;
    overflow:hidden;
    display:flex;
    align-items:center;
    justify-content:center;
    padding-bottom:1px;
  }
  &--appear{
    .c-menu-drawer{
      &__content{
        transform:translateY(100%);
        box-shadow:none;
        // @include desktop{
        //   transform:translateX(100%);
        // }
      }
      &__backdrop{
        opacity:0;
      }
    }
  }
  &--appear-active,
  &--appear-done,
  &--enter-active,
  &--enter-done,
  &--exit{
    .c-filter-form__submit-wrapper{
      transform:translateY(0);
    }
    .c-menu-drawer{
      &__content{
        transform:translateY(0);
        box-shadow:0px 0px 25px rgba(0,0,0,0.25);
        // @include desktop{
        //   transform:translateX(0);
        // }
      }
      &__backdrop{
        opacity:0.45;
      }
    }
  }

  &--exit-active,
  &--exit-done{
    .c-menu-drawer{
      .c-filter-form__submit-wrapper{
        transform:translateY(100%);
      }
      &__content{
        transform:translateY(100%);
        box-shadow:none;
        // @include desktop{
        //   transform:translateX(100%);
        //   box-shadow:none;
        // }
      }
      &__backdrop{
        opacity:0;
      }
    }
  }

  &--exit-active{
    .c-filter-form__submit-wrapper{
      transition:transform 0.25s ease-in;
    }
    .c-menu-drawer{
      &__content{
        transition:all 0.5s ease-in-out 0s;
      }
      &__backdrop{
        transition:opacity 0.5s linear 0.5s;
      }
    }
  }

  &--enter-active,
  &--appear-active{
    .c-filter-form__submit-wrapper{
      transition:transform 0.25s ease-in 0.25s;
    }
    .c-menu-drawer{
      &__content{
        transition:all 0.5s ease-out 0.5s;
      }
      &__backdrop{
        transition:opacity 0.5s linear;
      }
    }
  }
}