@import "sass/utils/vars";
@import "sass/utils/colors";
@import "sass/utils/typemixins";

.c-signin-form {
  margin-top: $spacer * 5;
  // width:calc(100% + #{$spacer*4});
  // margin-left:(-$spacer*2);
  width: 100%;
  @include tablet {
    width: 100%;
    margin-left: 0;
  }
  .form-control {
    margin-bottom: $spacer;
    input {
      width: calc(100% - #{$spacer * 2});
    }
  }
  .c-button--login {
    margin-top: $spacer * 5;
  }
  &__forgot {
    @include cta--text;
    text-decoration: underline;
    margin-left: $spacer * 2.25;
  }
}
