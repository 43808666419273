$charcoal: #131311;
$gray90: #76766b;
$gray80: #929287;
$gray40: rgba(0, 0, 0, 0.4);
$gray30: #dbdbd7;
$gray20: #e7e7e4;
$gray10: #f4f4f1;
$cream: #f6f2ee;
$darkcream: #e6e2df;
$white: #fff;
$deepblue: #102981;
$green: #107a2e;
$red: #cf3605;
$white40: rgba(255, 255, 255, 0.4);

$energetic: #f5bf76;
$focused: #4a2490;
$uplifted: #e83faa;
$euphoric: #ce4530;
$talkative: #d1aea6;
$creative: #6d233e;
$happy: #ee8760;
$hungry: #784f48;
$giggly: #d7853f;
$relaxed: #265070;
$sleepy: #010444;
$aroused: #1e4ad8;
$tingly: #99beb3;
$energetic-bg: transparentize(#f5bf76, 0.5);
$focused-bg: transparentize(#4a2490, 0.5);
$uplifted-bg: transparentize(#e83faa, 0.5);
$euphoric-bg: transparentize(#ce4530, 0.5);
$talkative-bg: transparentize(#d1aea6, 0.5);
$creative-bg: transparentize(#6d233e, 0.5);
$happy-bg: transparentize(#ee8760, 0.5);
$hungry-bg: transparentize(#784f48, 0.5);
$giggly-bg: transparentize(#d7853f, 0.5);
$relaxed-bg: transparentize(#265070, 0.5);
$sleepy-bg: transparentize(#010444, 0.5);
$aroused-bg: transparentize(#1e4ad8, 0.5);
$tingly-bg: transparentize(#99beb3, 0.5);

:export {
  charcoal: $charcoal;
  gray90: $gray90;
  gray80: $gray80;
  gray30: $gray30;
  gray20: $gray20;
  gray10: $gray10;
  cream: $cream;
  white: $white;
  deepblue: $deepblue;
  green: $green;
  red: $red;
  energetic: $energetic;
  focused: $focused;
  uplifted: $uplifted;
  euphoric: $euphoric;
  talkative: $talkative;
  creative: $creative;
  happy: $happy;
  hungry: $hungry;
  giggly: $giggly;
  relaxed: $relaxed;
  sleepy: $sleepy;
  aroused: $aroused;
  tingly: $tingly;
}
