@import "sass/utils/vars";
@import "sass/utils/colors";
@import "sass/utils/typemixins";
.c-order-items {
  .c-order-item {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    min-height: 135px;
    padding: $spacer * 3;
    background: $white;
    border-radius: $border-radius-desktop;
    overflow: hidden;
    margin-bottom: $spacer * 2;
    @include tablet {
      border-radius: $spacer * 1.25;
    }
    &__wrapper {
      transition: all 0.25s ease-in-out;
      overflow: hidden;
    }
    &__inner {
      position: relative;
      display: flex;
      padding-top: $spacer * 5;
      width: 100%;
      h3 {
        margin: 0;
        margin-bottom: 0;
        text-align: left !important;
      }
      @include tablet {
        align-items: center;
      }
    }
    &__icon {
      position: absolute;
      top: 0;
      width: $spacer * 3;
      height: $spacer * 3;
      > div {
        height: 100%;
        width: 100%;
      }
      svg {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
    &__detail-quantity {
      @include tablet {
        display: flex;
      }
    }
    &__detail {
      display: block;
      padding-right: $spacer * 3;
      @include desktop {
        padding-right: 0;
        max-width: $spacer * 35;
      }
      &__name {
        font-size: 14px;
        @include desktop {
          font-size: 20px;
          line-height: 120%;
        }
      }
      &__vendor {
        color: $gray80;
        line-height: 100%;
        font-size: 14px;

        @include desktop {
          font-size: 20px;
          line-height: 110%;
        }
      }
    }
    &__quantity-selector {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: auto;
      width: $spacer * 9;
      margin-top: $spacer;
      @include body--small;
      @include tablet {
        margin-top: 0;
        margin-left: $spacer * 3;
      }
      &__button {
        flex: 0 0 auto;
        &:disabled {
          cursor: not-allowed;
          opacity: 0.5;
        }
      }
      span {
        flex: 1;
        text-align: center;
      }
    }
    &__total {
      flex: 1;
      text-align: right;
      @include body--small;
      font-weight: 500;
      &__standard {
        display: inline-block;
        @include below-tablet {
          display: block;
          text-align: right;
        }
      }
      &--discounted {
        .c-order-item__total {
          &__standard {
            text-decoration: line-through;
            margin-right: $spacer;
            @include below-tablet {
              margin: 0;
            }
          }
        }
      }
    }
    &__remove {
      position: absolute;
      top: 0;
      right: 0;
      height: $spacer * 3.5;
      width: $spacer * 3.5;
      background: $gray10;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 100%;
      overflow: hidden;
      @include desktop {
        height: $spacer * 3;
        width: $spacer * 3;
      }
      svg {
        height: $spacer * 1.75;
        @include desktop {
          height: $spacer * 1.25;
        }
      }
    }
    // New CSS
    &__discount {
      margin-top: 15px;
      width: 100%;
      &__Box {
        display: flex;
        flex-wrap: wrap;
      }
      &__label {
        font-weight: 500;
        @include body--small;
      }
      &__name {
        flex: 1;
        margin-right: 10px;
      }
      &__name,
      &__amount {
        @include body--small;
        margin-top: 6px;
      }
      &__amount {
        align-self: flex-end;
      }
    }
  }
}
