@import 'sass/utils/vars';
@import 'sass/utils/typemixins';
h1, h2, h3, p{
  margin:0;
}
h1, .h1{
  @include h1;
}

h2, .h2{
  @include h2;
}

h3, .h3{
  @include h3;
}

p, .body{
  @include body;
  &--small{
    @include body--small;
  }
  &--xsmall{
    @include body--xsmall;
  }
}

.slug{
  @include slug;
}

.cta{
  &--primary{
    @include cta--primary;
  }
  &--small{
    @include cta--small;
  }
  &--text{
    @include cta--text;
  }
}

.price{
  @include price;
}

.tag{
  @include tag;
}

.meta{
  @include meta;
}

a{
  text-decoration:none;
  color:$charcoal;
}