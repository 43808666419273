@import 'sass/utils/colors';
.product-type-icon{
  path,line, circle, rect, polyline{
    stroke: $charcoal;
    transition: all 1s;
    .enter-active &,
    .enter-done &,
    .exit &  {
      stroke:$white;
      transition: all 0.5s linear 0.25s;
    }
    .enter,
    .exit-active &,
    .exit-done & {
      transition: all 0.5s linear 0s;
      stroke: $charcoal;
    }

  }
  path,line, circle, rect, polyline{
    fill:none;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;
    stroke-width:1px;
  }
}

.i-product-type{
  &--beverages{
    &--st0{
      fill:none;
      stroke-linecap:round;
      stroke-linejoin:round;
      stroke-miterlimit:10;
    }
  }
  &--candy{
    &--st0{
      fill:none;
      stroke-width:3.3241px;
      stroke-linecap:round;
      stroke-linejoin:round;
      stroke-miterlimit:10;
    }
  }
  &--cartridge{
    &--st0{
      fill:none;
      stroke-linecap:round;
      stroke-linejoin:round;
      stroke-miterlimit:10;
    }
  }
  &--edibles{
    &--st0{
      fill:none;
      stroke-linecap:round;
      stroke-linejoin:round;
      stroke-miterlimit:10;
    }
  }
  &--extract{
    &--st0{
      fill:none;
      stroke-linecap:round;
      stroke-linejoin:round;
      stroke-miterlimit:10;
    }
  }
  &--flower{
    &--st0{
      fill:none;
      stroke-linecap:round;
      stroke-linejoin:round;
      stroke-miterlimit:10;
      stroke-width:4px;
    }
  }
  &--oil{
    &--st0{
      fill:none;
      stroke-linecap:round;
      stroke-linejoin:round;
      stroke-miterlimit:10;
    }
  }
  &--patch{
    &--st0{
      fill:none;
      stroke-width:3.3241px;
      stroke-linecap:round;
      stroke-linejoin:round;
      stroke-miterlimit:10;
    }
  }
  &--pills{
    &--st0{
      fill:none;
      stroke-linecap:round;
      stroke-linejoin:round;
      stroke-miterlimit:10;
    }
  }
  &--prerolls{
    &--st0{
      fill:none;
      stroke-linecap:round;
      stroke-linejoin:round;
      stroke-miterlimit:10;
    }
  }
  &--spray{
    &--st0{
      fill:none;
      stroke-linecap:round;
      stroke-linejoin:round;
      stroke-miterlimit:10;
    }
  }
  &--topicals{
    &--st0{
      fill:none;
      stroke-linecap:round;
      stroke-linejoin:round;
      stroke-miterlimit:10;
    }
  }
  &--vapes{
    &--st0{
      fill:none;
      stroke-linecap:round;
      stroke-linejoin:round;
      stroke-miterlimit:10;
    }
  }
  &--accessories{
    &--st0{
      fill:none;
      stroke-width:3.3241px;
      stroke-linecap:round;
      stroke-linejoin:round;
      stroke-miterlimit:10;
    }
  }
}