@import 'sass/utils/vars';
.c-dispensary-filters{
  display:grid;
  width:100%;
  grid-template-columns: repeat(12, 1fr);
  column-gap: $spacer;
  
  .slug &{
    display:none;
  }
  @include desktop{
    display:none;
    .slug &{
      display:grid;
      width:400px;
      margin:0 !important;
    }
  }
  &.l-maxwidth{
    margin:$spacer auto $spacer*5;
  }
  &__column--all{
    grid-column:1/4;
  }
  &__column--recreational{
    grid-column:4/9;
  }
  &__column--medical{
    grid-column:9/13;
  }
}