@import "utils/vars";
@import "utils/colors";
@import "utils/grid";
@import "webfonts/fonts";
@import "typography";

* {
  box-sizing: border-box;
}

html,
body {
  // font-family: "Roboto", Arial, sans-serif !important;
  font-family: 'Sneak', Arial, sans-serif !important;
  font-weight: normal;
}

body {
  background: $cream;
  &.no-scroll {
    overflow: hidden;
  }
  // @include below-tablet{
  //   padding-top:0;
  // }
}

* {
  // -webkit-touch-callout: none;
  // -webkit-user-select: none;
  // -khtml-user-select: none;
  // -moz-user-select: none;
  // -ms-user-select: none;
  // user-select: none;
  -webkit-tap-highlight-color: transparent;
}

.page-sizer {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
}

button {
  outline: 0;
  border: 0;
  background: transparent;
  cursor: pointer;
  &:focus {
    outline: 0;
  }
  &:active {
    outline: none;
  }
}

figure {
  margin: 0;
}

#root {
  @include desktop {
    // min-height:100vh;
    display: flex;
    flex-direction: column;
  }
}

* {
  // backface-visibility:hidden;
  // -webkit-backface-visibility: hidden;
  // perspective:1000;
  // -webkit-perspective: 1000;
}

.fade-enter {
  opacity: 0.01;
}

.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in;
}

.fade-exit {
  opacity: 1;
}

.fade-exit.fade-exit-active {
  opacity: 0.01;
  transition: opacity 500ms ease-in;
}

#page-sizer {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;

  .c-page-wrapper--dispensary--slug-products & {
    display: none;
  }
}
