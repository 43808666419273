@import "sass/utils/colors";
@import "sass/utils/vars";
@import "sass/utils/typemixins";

.c-forgot-password {
  background: $cream;
  h1 {
    padding-right: $spacer * 2;
    @include tablet {
      text-align: center;
      padding-right: 0;
    }
  }
  &__box {
    padding-top: 50px;
  }
  p {
    max-width: 450px;
    margin: $spacer auto;
    @include tablet {
      text-align: center;
    }
    strong {
      color: $deepblue;
    }
  }
  .c-button {
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    // max-width:100%;
  }
  .c-icon-button {
    text-align: left;
  }
  &__signup {
    margin: $spacer * 5 0;
    text-align: center;
    @include body--small;
    a {
      font-weight: 700;
    }
  }
  &__options {
    max-width: 351px;
    margin: 0 auto;
  }
}
