@import 'sass/utils/vars';
@import 'sass/utils/colors';
@import 'sass/utils/typemixins';
.c-settings-form{
  width:calc(100% + #{$spacer*6});
  margin:$spacer*5 (-$spacer*3) 0;
  @include tablet{
    max-width:360px;
    margin:$spacer*6 auto 0;
  }
  .form-control{
    margin-bottom:$spacer;
  }
  .c-button{
    margin:0 auto $spacer;
    max-width:360px;
  }
  button.c-button{
    margin-top:$spacer*6;
  }
  &__reset-password{
    @include cta--text;
    text-decoration:underline;
    margin-top:$spacer*3;
    display:block;
    // margin-left:$spacer*3;
  }
  p{
    margin:$spacer*3 0 $spacer;
  }
}