@import 'sass/utils/vars';
@import 'sass/utils/colors';
@import 'sass/utils/layout';
@import 'sass/utils/typemixins';

.c-dispensary{
  background:$white;
  // min-height:calc(100vh - 118px);
  @include below-desktop{
    padding:0 $spacer*4 $spacer*5;
  }
  @include desktop{
    background:$cream;
  }

  h1{
    margin:$spacer*5 0 $spacer;
    @include desktop{
      margin-top:0;
    }
  }

  &__inner{
    position:relative;
    @include l-maxwidth;
  }
  
  figure{
    position:relative;
    width:100%;
    border-radius:$border-radius;
    overflow:hidden;
    img{
      position:absolute;
      top:0;
      left:0;
      height:100%;
      width:100%;
      object-fit:cover;
      transition:opacity 0.25s linear;
    }
    &:before{
      content:'';
      display:block;
      padding-top:51.25%;
      position:relative;
      background:$cream;

      @include desktop{
        background:$darkcream;
        height:275px;
        padding-top:0;
      }
    }
    @include desktop{
      position:absolute;
      max-width:calc(50% - #{-$spacer*10});
      margin:0 (-$spacer*5);
      border-radius:$border-radius 0 0 $border-radius;
      z-index:2;
    }
  }
  &__tags{
    .c-tag{
      cursor:default;
    }
  }
  &__map{
    display:flex;
    align-items:flex-start;
    margin-top:$spacer*4  ;
    @include cta--primary;
    > span{
      text-decoration:underline;
    }
    svg{
      margin-top:-$spacer/4;
      margin-right:$spacer;
    }
  }
  &__phone{
    display:flex;
    align-items:flex-start;
    margin-top:$spacer*2;
    @include cta--primary;
    > span{
      text-decoration:underline;
    }
    svg{
      margin-right:$spacer*1.5;
      margin-left:$spacer/2;
    }
  }

  &__hours{
    width:100%;
    padding:$spacer*3 $spacer*3;
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
    border:1px solid $gray30;
    border-radius:$border-radius*1.25;
    margin-top:$spacer*5; 
    @include desktop{
      padding:$spacer*3 $spacer*5;

    }
  }
  &__full-hours{
    overflow:hidden;
    max-height:0;
    opacity:0;
    width:100%;
    &.enter-active,
    &.enter-done,
    &.exit{
      max-height:400px;
      opacity:1;
    }
    &.enter-active{
      transition:max-height 0.5s ease-in-out 0s, opacity 0.25s linear 0.5s;
    }
    &.exit-active{
      max-height:0;
      opacity:0;
      transition:max-height 0.5s ease-in-out 0.25s, opacity 0.25s linear 0s;
    }
  }

  &__hours-entry{
    display:flex;
    justify-content:flex-start;
    width:100%;
    padding:$spacer*1.5 0;
    border-bottom:1px solid $gray20;
    &:last-of-type{
      border-bottom:0;
      padding-bottom:0;
    }
    &:first-of-type{
      padding-top:$spacer*5;
    }
  }

  &__hours-day{
    width:120px;
    color:$gray90;
    text-transform:uppercase;
    @include slug;
  }
  &__hours-time{
    @include body--small;
  }

  &__status{
    &--open{
      strong{
        color:$green;
      }
    }
    &--closed{
      strong{
        color:$red;
      }
    }
  }
  &__toggle-hours{
    width:100%;
    margin:$spacer 0;
    text-align:center;
    @include cta--text;
  }
  &__order-note{
    @include body--small;
    margin:$spacer*5 0;
    color:$gray40;
    font-weight:bold;
    strong{
      color:$charcoal;
    }
    @include desktop{
      margin-bottom:0;
    }
  }

  &__details{
    @include desktop{
      position:relative;
      margin-right:-$spacer*5;
      z-index:0;
      max-width:50%;
      background:$white;
      border-radius:$border-radius-desktop/2;
      padding:$spacer*5;
      float:right;
      margin-top:-$spacer*7;
      box-shadow: 0px 84px 84px rgba(117, 87, 53, 0.1);
    }
  }
  &__actions{
    @include tablet {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
    
    @include desktop{
      position:absolute;
      top:330px;
      width:100%; 
      max-width:calc(50% - #{-$spacer*10});
      left:-$spacer*5;
      margin-top:$spacer*2;
      display:flex;
      flex-direction:column;
      align-items:center;
    }
  }

  .c-button{
    margin-bottom:$spacer;
    &--default{
      background-color:$charcoal;
      transition:background-color 0.25s linear;
      &--active{
        background-color:$deepblue !important;
      }
    }
  }
}

.c-dispensary-distance{
  display:inline-block;
  flex: 0 0 auto;
  max-width:0;
  opacity:0;
  overflow:hidden;
  margin-right:0;
  background:$gray10;
  padding:0;
  text-decoration:none;
  &.appear-active,
  &.appear-done,
  &.enter-active,
  &.enter-done,
  &.exit{
    opacity:1;
    padding:0 ($spacer*.75);
    margin-right:$spacer/4;
    max-width:80px;
  }
  
  &.enter-active{
    transition:all 0.75s ease-in-out, opacity 0.25s linear 0.5s;
  }

  &.appear-active{
    transition:none;
  }
}