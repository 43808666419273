@import 'sass/utils/vars';
@import 'sass/utils/colors';
@import 'sass/utils/typemixins';
.c-signin-social{
  display:flex;
  flex-direction:column;
  align-items:center;
  .c-icon-button{
    position:relative;
    margin-bottom:$spacer;
    text-align:center;
    max-width:314px;
    &__icon{
      position:absolute;
      left:$spacer*2;
      top:$spacer*2;
    }
    span{
      text-align:center;
    }
    &:last-of-type{
      margin-bottom:0;
    }
    &__icon{
      height:24px;
      width:24px;
      img{
        margin:0 auto;
      }
    }
  }
}