@import 'sass/utils/vars';
@import 'sass/utils/colors';
@import 'sass/utils/typemixins';
.c-pdp-match{
  margin-top:$spacer*10;
  position:relative;
  &__inner{
    position:relative; 
    margin-bottom:$spacer*10;
  }
  &__percentage{
    position:absolute;
    top:50%;
    left:50%;
    transform:translate(-50%, -50%);
    &__number{
      font-size:80px;
      line-height:96px;
      text-align:center;
      &--grayed{
        color:$gray20;
      }
    }
  }
  &__label{
    position:absolute;
    bottom:0;
    left:0;
    right:0;
    text-align:center;
    @include body--small;
  }
}