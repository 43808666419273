@import "sass/utils/vars";
@import "sass/utils/colors";
.c-kiosk-options {
  width: 100%;
  max-width: calc(100% - 48px);
  left: 24px;
  position: absolute;
  bottom: 20px;
  padding: $spacer * 2.5 $spacer * 3;
  display: flex;
  flex-wrap: wrap;
  opacity: 1;
  transition: opacity 0.25s linear 1.8s, max-height 1s linear 0.875s;
  max-height: 296px;
  @include below-desktop{
    padding: $spacer * 2.5 0;
  }
//   &--has-embed-modal{
//     pointer-events: none;
//     cursor: default;
//   }
  &--selector-open {
    z-index: -1;
    opacity: 0;
    max-height: 0;
    bottom: auto;
    top: 0;
    transition: opacity 0.25s linear, margin-bottom 1s linear 0.875s,
      max-height 1s linear 0.875s;
  }
  &__big-button {
    flex: 0 0 auto;
    width: calc(50% - #{$spacer * 1.25});
    // height: $spacer * 23;
    height: $spacer * 20;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 28px;
    border: 1px solid $gray30;
    border-radius: $border-radius;
    @include below-desktop{
        font-size: 24px;
    }
  }
  button:first-of-type {
    margin-right: $spacer * 2.5;
  }
  a {
    margin-right: 0;
  }
  .c-button {
    margin-top: $spacer * 2.5;
    max-width: 100%;
    width: 100%;
  }
}
