@import "sass/utils/vars";
@import "sass/utils/colors";
@import "sass/utils/typemixins";
.c-pdp-description-image {
  margin: $spacer * 10 0;
  .slug {
    margin-bottom: $spacer * 2;
  }
  &__description {
    max-height: 65px;
    overflow: hidden;
    transition: max-height 0.5s ease-in-out;
    @include desktop {
      max-height: 85px;
    }
    &,
    p:not(:last-child) {
      margin-bottom: $spacer * 2;
    }
    p,
    &--container {
      @include body;
    }
    &--expanded {
      max-height: 500px;
    }
  }
  button {
    padding: 0;
    @include cta--text;
  }
  &__figure {
    display: block;
    position: relative;
    border: 1px solid $gray20;
    margin: $spacer * 10 0;
    width: 100%;
    padding-top: 100%;
    img {
      position: absolute;
      left: 50%;
      top: 50%;
      object-fit: contain;
      width: 100%;
      height: 100%;
      transform: translate(-50%, -50%);
    }
  }
}
