@import "sass/utils/vars";
@import "sass/utils/colors";
.c-places-search {
  position: relative;
  z-index: 5;
  // width:calc(100% + #{$spacer*3});
  // margin:0  (-$spacer*1.5) 0;
  width: 100%;
  margin: 0 0 0;
  transition: box-shadow 0.5s linear;
  // @include desktop{
  //   width:100%;
  //   margin:0;
  // }

  &__inner {
    margin-top: $spacer * 2.5;
  }

  &__wrapper {
    position: relative;
    z-index: 2;
    height: 94px;
    margin: $spacer * 2.5 0 0;

    &--fixed {
      z-index: 5;
      .c-places-search {
        position: fixed;
        padding-top: 0;
        top: $spacer;
        left: 50%;
        max-width: calc(100vw - #{$spacer * 5});
        z-index: 10;
        transform: translateX(-50%);

        margin: 0;
        &__inner {
          box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.25);
          background: $cream;
          border-radius: $border-radius;
          overflow: hidden;
          @include desktop {
            box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
          }
        }
        @include desktop {
          max-width: 920px;
        }
        &__results {
          position: fixed;
          top: 98px;
          left: 50%;
          transform: translateX(-50%);
          z-index: 0;
          &__inner {
            box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.25);
            border-radius: 0 0 $border-radius $border-radius;
            overflow: hidden;
            @include desktop {
              box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
            }
          }
        }
      }
    }
  }
  .form-control {
    &--active {
      border: 1px solid $deepblue;
      .form-control__label {
        color: $deepblue;
      }
    }
    &--dirty {
      border: 1px solid $deepblue;
    }
  }
  &__results {
    height: 0;
    overflow: visible;
    &__inner {
      box-shadow: none;
      transition: box-shadow 0.5s linear;
    }
    &:not(.c-places-search__results--highlighted) {
      .c-places-result:hover {
        background: $gray30;
      }
    }
  }
}

.c-places-result {
  width: 100%;
  height: $spacer * 7;
  padding: 0 $spacer;
  background: $white;
  border-bottom: 1px solid $gray20;
  cursor: pointer;
  &:first-of-type {
    padding-top: $spacer;
    height: $spacer * 8;
  }
  &:last-of-type {
    border-radius: 0 0 4px 4px;
    border-bottom: 0px;
  }
  &--highlighted {
    background: $gray30;
  }
  &__content {
    display: flex;
    align-items: center;
    height: 100%;
  }
  &__icon {
    width: auto;
    margin-right: $spacer;
    padding-left: $spacer/2;
    svg {
      width: $spacer * 3;
    }
  }
}
