@import 'sass/utils/vars';
@import 'sass/utils/colors';
@import 'sass/utils/feelingcolors';
@import 'sass/utils/typemixins';
@import 'sass/utils/typemixins';

.c-card-gradient{
  width:100%;
  height:100%;
  position: absolute;
  
  &__placeholder{
    position:absolute;
    top:$spacer*1.5;
    right:$spacer*1.5;
    width:$spacer*4;
    height:$spacer*4;
    border-radius:100%;
    overflow:hidden;
    opacity:1;
    transition:opacity 0.25s linear 0.5s;
    display:none;
    .enter-active &,
    .enter-done &{
      opacity:0;
      transition:opacity 0.25s linear 0s;
    }
    > div{
      position:absolute;
      top:50%;
      left:50%;
      width:calc(100% + 30px);
      height:calc(100% + 30px);
      filter:blur(15px);
      transform:translate(-50%, -50%);
      border-radius:100%;
    }
  }

  &__default{
    height:100%;
    width:100%;
    object-fit:cover;
    background-size:cover;
    img{
      height:100%;
      width:100%;
      object-fit:cover;
      background-size:cover;
      transition:opacity 0.25s linear;
    }
  }
  .c-product-card & {
    
    &__placeholder{
      display:block;
    }
  }

  .c-product-card--enter-active &,
  .c-product-card--enter-done &{
    opacity:1;
    transition:opacity 1.25s ease-in-out 0.25s;

    &__placeholder{
      opacity:0;
      transition:opacity 0.25s linear 0s;
    }
  }
  .c-pdp-topper & {
    z-index:0;
  }
  background:$gray20;
  overflow:hidden;
  &--safari-blur{
    filter:blur(5px);
    border-radius: 20px;
  }
  &__noise{
    position:absolute;
    top:0;
    left:0;
    right:0;
    bottom:0;
    z-index:2;
    mix-blend-mode: multiply;
    opacity:0.5;
    &--safari {
      mix-blend-mode: normal;
    }
  }
  
  canvas{
    //top:-5%;
    //left: -5%;
    //bottom: -5%;
    //right: -5%;
    width:100%;
    height:100%;
    //position:absolute;
    //width:150%;
    //height:150%;
    //top:-25%;
    //left:-25%;
    //right:-25%;
    //bottom:-25%;
    //z-index:1;
    transition:transform 4s ease-in-out 0.5s;

    .c-pdp-topper & {
      //height:300%;
      //top:-100%;
      //bottom:-100%;
    }
  }

}