@import 'sass/utils/colors';

.c-splash-screen {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 5555555;
  background: url("../../background.png");
  background-size: cover;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;

  &--enter-active,
  &--enter-done,
  &--exit{
    opacity:1;
  }
  &--exit-done,
  &--exit-active{
    opacity:0;
  }
  &--enter-active,
  &--exit-active{
    transition: opacity 0.3s ease-in-out;
  }
}

.c-splash-screen__content {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  
  svg {
    width: 88px;
    height: 27px;
  }
  
  h1 {
    margin-top: 8px;
    color: $white;
    font-weight: normal;
    font-size: 16px;
    line-height: 140%;
  }
}
