@import 'sass/utils/vars';
@import 'sass/utils/colors';
.c-page-wrapper{
  padding-top:$spacer*8.5;
  &--dispensary-slug{
    background:$white;
  }
  &--dispensary--slug-product--id{
    @include below-tablet{
      background:$white;
    }
  }
  &--dispensary--slug{
    @include below-tablet{
      background:$white;
    }
  }
  &--dispensary--slug-product--id{
    footer{
      margin-top:-$spacer*2.5;
      padding-top:$spacer*2;
    }
  }
  &--recommendations{
    padding-top:68px;
    @include desktop{
      padding-top:0;
    }
  }
  &--home:not(&--kiosk){
    @include desktop{
      padding-top:0;
    }
    header{
      @include desktop{
        box-shadow:none !important;
      }
    }
  }
} 
