@import 'sass/utils/vars';
@import 'sass/utils/colors';
.c-profile-form{
  width:calc(100% + #{$spacer*6});
  margin: $spacer*5 (-$spacer*3);
  opacity:1;
  transition:opacity 0.25s linear;
  &--loading{
    opacity:0.25;
  }
  @include tablet{
    max-width:360px;
    margin:0 auto;
  }
 
  .form-control{
    margin-bottom:$spacer;
  }
  h3{
    max-width:calc(100% - #{$spacer*6});
    margin:$spacer*5 auto $spacer*3;
  }
  .c-button{
    margin:$spacer auto;
    max-width:314px;
  }
  button.c-button{
    margin-top:$spacer*5;
  }

  .c-button--saveMulti{
    margin:$spacer*5 0 0;
  }
}